import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { InputError } from "../../commons/MicroComponents";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, InputWithLabel } from "../../components/micro";
import ReactSelect from "react-select";
import { Eye, EyeSlash } from "../../assets/svg/AllSvg";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createStaffs, getStaff } from "../../redux/staffs/slice";
import RippleLoader from "../../commons/RippleLoader";
import { getCountryData } from "../../redux/address/slice";
import { getAllRoleList } from "../../redux/accounts/slice";
import moment from "moment/moment";

const CreateEditStaffAccount = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [countryArray, setCountryArray] = useState([]);
  const [allRoleListArray, setAllRoleListArray] = useState([]);
  const location = useLocation();
  const staffData = location?.state?.element;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector
  const {
    staffDetails,
    staffLoading,
    countryData,
    countryLoading,
    allRoleList,
    allRoleLoading,
  } = useSelector((store) => ({
    // Staff Details
    staffDetails: store?.staffsData?.getStaffStatus?.data,
    staffLoading: store?.staffsData?.loading,
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data,
    countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    // all RoleL ist
    allRoleList: store?.accountsData?.getAllRoleListStatus?.data,
    allRoleLoading: store?.accountsData?.getAllRoleListStatus?.loading,
  }));

  // get Staff and Country
  useEffect(() => {
    if (staffData?.id) {
      dispatch(getStaff(staffData?.id));
    }
  }, [staffData]);

  useEffect(() => {
    dispatch(getCountryData());
    dispatch(getAllRoleList());
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
    if (allRoleList?.data) {
      let data = allRoleList.data?.map((x) => ({ ...x, label: x.title }));
      setAllRoleListArray(data);
    }
  }, [countryData, allRoleList]);

  const countryValueGet = {
    label: staffDetails?.data?.country?.name,
    ...staffDetails?.data?.country,
  };

  const roleValueGet = {
    label: staffDetails?.data?.roles?.title,
    ...staffDetails?.data?.roles,
  };

  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Staff Account
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Staff Account"}
          breadCrumbParent2={"Staff Account"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        {staffLoading ? (
          <RippleLoader />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullName: staffData?.id ? staffDetails?.data?.full_name : "",
              email: staffData?.id ? staffDetails?.data?.email : "",
              gender: staffData?.id ? staffDetails?.data?.gender : "",
              dob: staffData?.id ? staffDetails?.data?.dob : "",
              phone: staffData?.id ? staffDetails?.data?.phone : "",
              country: staffData?.id
                ? staffDetails?.data?.country
                  ? countryValueGet
                  : ""
                : "",
              countryId: staffData?.id ? staffDetails?.data?.country_id : "",
              role: staffData?.id
                ? staffDetails?.data?.role
                  ? roleValueGet
                  : ""
                : "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string().min(3).required("Please Enter Name"),
              email: Yup.string().min(3).required("Please Enter E-mail"),
              gender: Yup.string().required("Please Select your Gender"),
              dob: Yup.string().nullable().required("Please Select Birth Date"),
              phone: Yup.string()
                .required("please Enter Mobile")
                .max(10, "Mobile is too long - should be 10 chars maximum."),
              role: Yup.object().required("Please Select Role"),
              country: Yup.object().required("Please Select Country"),
              password: staffData?.id
                ? Yup.string()
                    .min(
                      8,
                      "Password is too short - should be 8 chars minimum."
                    )
                    .matches(
                      /[a-zA-Z]/,
                      "Password can only contain Latin letters."
                    )
                : Yup.string()
                    .required("Please Enter Password")
                    .min(
                      8,
                      "Password is too short - should be 8 chars minimum."
                    )
                    .matches(
                      /[a-zA-Z]/,
                      "Password can only contain Latin letters."
                    ),
              confirmPassword: staffData?.id
                ? Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords must match"
                  )
                : Yup.string()
                    .required("Please Enter Confirm Password")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
            })}
            onSubmit={(values, { resetForm }) => {
              const payload = {
                full_name: values.fullName,
                email: values.email,
                gender: values.gender,
                dob: moment(values.dob).format("YYYY-MM-DD"),
                phone: values.phone,
                country_id: values.countryId,
                role: values.role.id,
                password: values.password,
                confirmPassword: values.confirmPassword,
              };
              if (staffData?.id) {
                dispatch(createStaffs({ id: staffData?.id, ...payload })).then(
                  (res) => {
                    resetForm();
                    if (res.type === "createStaffs/fulfilled") {
                      navigate("/staff-accounts");
                    }
                  }
                );
              } else {
                dispatch(createStaffs(payload)).then((res) => {
                  resetForm();
                  if (res.type === "createStaffs/fulfilled") {
                    navigate("/staff-accounts");
                  }
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-6 mb-4">
                  {/* Name */}
                  <InputWithLabel
                    className={
                      errors.fullName && touched.fullName
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="fullName"
                    label="Name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                    errors={errors.fullName && touched.fullName}
                    errorsText={errors.fullName}
                  />
                  {/* Email */}
                  <InputWithLabel
                    className={
                      errors.email && touched.email
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="email"
                    label="Email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    errors={errors.email && touched.email}
                    errorsText={errors.email}
                  />
                  {/* Gender */}
                  <div>
                    <div className="flex items-center space-x-3">
                      <label
                        form="gender"
                        className="text-light_grey text-base"
                      >
                        Gender
                      </label>
                      <div>
                        <label for="male" className="relative cursor-pointer">
                          <Input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            className="sr-only peer"
                            checked={values.gender === "male"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="px-4 py-2 border border-theme text-theme rounded-full peer-checked:bg-theme peer-checked:text-white">
                            Male
                          </div>
                        </label>
                      </div>
                      <div>
                        <label for="female" className="relative cursor-pointer">
                          <Input
                            type="radio"
                            id="female"
                            value="female"
                            name="gender"
                            className="sr-only peer"
                            checked={values.gender === "female"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="px-4 py-2 border border-theme text-theme rounded-full peer-checked:bg-theme peer-checked:text-white">
                            Female
                          </div>
                        </label>
                      </div>
                      <div>
                        <label for="other" className="relative cursor-pointer">
                          <Input
                            type="radio"
                            id="other"
                            name="gender"
                            value="other"
                            className="sr-only peer"
                            checked={values.gender === "other"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="px-4 py-2 border border-theme text-theme rounded-full peer-checked:bg-theme peer-checked:text-white">
                            Other
                          </div>
                        </label>
                      </div>
                    </div>
                    {errors.gender && touched.gender ? (
                      <>
                        <InputError errorTitle={errors.gender} />
                      </>
                    ) : null}
                  </div>
                  {/* Birth Date */}
                  <div>
                    <div
                      className={`${
                        errors.dob && touched.dob ? ` input-error ` : ` `
                      } relative bg-white transition duration-300 rounded plus-number-inputs`}
                    >
                      <ReactDatePicker
                        selected={values.dob}
                        onChange={(date) =>
                          setFieldValue("dob", new Date(date))
                        }
                        selectsStart
                        maxDate={new Date()}
                        id="dob"
                        dateFormat="dd/MM/yyyy"
                        placeholderText={"Select Birth Date"}
                        className={`border border-secondary_grey ${
                          errors.dob && touched.dob ? "error-forms" : ""
                        } rounded w-full px-3 py-2.5`}
                      />
                      <label
                        htmlFor="dob"
                        className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Birth Date
                      </label>
                    </div>
                    {errors.dob && touched.dob ? (
                      <InputError errorTitle={errors.dob} />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    {/* Country */}
                    <div>
                      <div
                        className={`${
                          errors.country && touched.country
                            ? ` input-error `
                            : ""
                        } relative bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactSelect
                          className={"text-black p-0"}
                          id="country"
                          placeholder="Country"
                          options={countryArray}
                          isLoading={countryLoading}
                          getOptionValue={(countryArray) => countryArray.name}
                          value={
                            countryArray?.length > 0 &&
                            countryArray?.find((op) => {
                              return op.label === values.country.label;
                            })
                          }
                          onChange={(e) => {
                            setFieldValue("country", e);
                            setFieldValue("countryId", e?.id);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: `1px solid ${
                                errors.country && touched.country
                                  ? "red"
                                  : "#0000003B"
                              }`,
                              borderRadius: "0.25rem",
                              padding: "6px 4px",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0000003B",
                              },
                            }),
                          }}
                        />
                        <label
                          htmlFor="country"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Country
                        </label>
                      </div>
                      {errors.country && touched.country ? (
                        <>
                          <InputError errorTitle={errors.country} />
                        </>
                      ) : null}
                    </div>
                    {/* Mobile */}
                    <div className="col-span-2">
                      <InputWithLabel
                        className={
                          errors.phone && touched.phone
                            ? ` input-error `
                            : ` border `
                        }
                        idFromName="phone"
                        label="Mobile"
                        type={"number"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        errors={errors.phone && touched.phone}
                        errorsText={errors.phone}
                      />
                    </div>
                  </div>
                  {/* Role */}
                  <div>
                    <div
                      className={`${
                        errors.role && touched.role ? ` input-error ` : ""
                      } relative bg-white transition duration-300 rounded plus-number-inputs`}
                    >
                      <ReactSelect
                        className={"text-black p-0"}
                        id="role"
                        placeholder="Select Role"
                        options={allRoleListArray}
                        isLoading={allRoleLoading}
                        getOptionValue={(allRoleListArray) =>
                          allRoleListArray.title
                        }
                        value={
                          allRoleListArray?.length > 0 &&
                          allRoleListArray?.find((op) => {
                            return op.title === values.role.label;
                          })
                        }
                        onChange={(e) => {
                          setFieldValue("role", e);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.role && touched.role ? "red" : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />
                      <label
                        htmlFor="role"
                        className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Role
                      </label>
                    </div>
                    {errors.role && touched.role ? (
                      <>
                        <InputError errorTitle={errors.role} />
                      </>
                    ) : null}
                  </div>
                  {/* Password */}
                  <InputWithLabel
                    className={
                      errors.password && touched.password
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="password"
                    label="Password"
                    type={passwordType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    errors={errors.password && touched.password}
                    errorsText={errors.password}
                    icon={
                      <p
                        className="p-2 absolute top-1.5 right-0 rounded bg-transparent z-20"
                        onClick={() => {
                          if (passwordType === "password") {
                            setPasswordType("text");
                            return;
                          }
                          setPasswordType("password");
                        }}
                      >
                        {passwordType === "password" ? <EyeSlash /> : <Eye />}
                      </p>
                    }
                  />
                  {/* Confirm Password */}
                  <InputWithLabel
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="confirmPassword"
                    label="Confirm Password"
                    type={confirmPasswordType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    errors={errors.confirmPassword && touched.confirmPassword}
                    errorsText={errors.confirmPassword}
                    icon={
                      <p
                        className="p-2 absolute top-1.5 right-0 rounded bg-transparent"
                        onClick={() => {
                          if (confirmPasswordType === "password") {
                            setConfirmPasswordType("text");
                            return;
                          }
                          setConfirmPasswordType("password");
                        }}
                      >
                        {confirmPasswordType === "password" ? (
                          <EyeSlash />
                        ) : (
                          <Eye />
                        )}
                      </p>
                    }
                  />
                </div>
                <div className="flex justify-end gap-4 pt-3">
                  <button
                    className="btn-outline uppercase"
                    onClick={() => {
                      navigate("/staff-accounts");
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn-theme uppercase">
                    {staffData?.id ? "Save" : "Create"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default CreateEditStaffAccount;
