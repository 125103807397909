import React from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import Tab from "../../../commons/Tab";
import PersonalDetails from "./PersonalDetails";
import AccountInformation from "./AccountInformation";
import Documents from "./Documents";

const CreateEditCustomers = () => {
  const tabs = [
    { label: "Personal Details", content: <PersonalDetails /> },
    { label: "Account Information", content: <AccountInformation /> },
    { label: "Documents", content: <Documents /> },
  ];
  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Create Customers
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Customers"}
          breadCrumbParent2={"Customers"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <Tab tabs={tabs} btnClass="w-full font-semibold mb-5" />
      </div>
    </>
  );
};

export default CreateEditCustomers;
