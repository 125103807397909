import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Staffs List
export const getStaffsList = createAsyncThunk(
  "getStaffsList",
  async (
    { search, status, pageLimit, pageCount, role },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `staffs?search=${search}&role=${
          role ?? ""
        }&status=${status}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Staff
export const getStaff = createAsyncThunk(
  "getStaff",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`/staffs/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Edit Staffs
export const createStaffs = createAsyncThunk(
  "createStaffs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`staffs`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update Staff Status
export const updateStaffStatus = createAsyncThunk(
  "updateStaffStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`staffs/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const staffsSlice = createSlice({
  name: "staffs",
  initialState: initialStates,
  extraReducers: {
    // get Staffs List
    [getStaffsList.pending]: (state) => {
      state.loading = true;
    },
    [getStaffsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.getStaffsListStatus.data = action.payload;
      state.error = false;
    },
    [getStaffsList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Get Staff
    [getStaff.pending]: (state) => {
      state.loading = true;
    },
    [getStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.getStaffStatus.data = action.payload;
      state.error = false;
    },
    [getStaff.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // Create Edit staffs
    [createStaffs.pending]: (state) => {
      state.loading = true;
    },
    [createStaffs.fulfilled]: (state, action) => {
      state.loading = false;
      state.createStaffsStatus.data = action.payload;
      state.error = false;
    },
    [createStaffs.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // update Staff Status
    [updateStaffStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateStaffStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateStaffStatusStatus.data = action.payload;
      state.error = false;
    },
    [updateStaffStatus.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});
const { reducer } = staffsSlice;
export default reducer;
