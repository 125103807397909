import React, { useState } from "react";
import { AddButton, Badge } from "../../../components/micro";
import EditOrganisationPopup from "./EditOrganisationPopup";

const Organisation = () => {
  const [showEditOrganisationPopup, setShowEditOrganisationPopup] =
    useState(false);
  const [organisationData, setOrganisationData] = useState({});
  return (
    <>
      <div className="bg-light_secondary rounded-xl px-8 mb-5">
        {/* Directors */}
        <div className="border-b border-secondary_grey py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">Directors</p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setOrganisationData({});
                setShowEditOrganisationPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="space-y-1">
              <p className="text-xs font-semibold">Name</p>
              <p>Name 1</p>
              <p className="text-xs text-light_grey font-semibold">
                +65 9348 9923
              </p>
              <p className="text-xs text-light_grey font-semibold">
                name1@gmail.com
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-xs font-semibold">Role:</p>
              <p className="text-xs text-light_grey font-semibold">
                Company Admin
              </p>
              <p className="text-xs text-light_grey font-semibold">Director</p>
              <p className="text-xs text-light_grey font-semibold">
                Shareholder
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">
                Identity Verification Status
              </p>
              <Badge
                className={`text-white border-success bg-success inline-block font-medium text-sm !py-1`}
                value={"Approved"}
              />
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">KYC Status</p>
              <Badge
                className={`border-success text-success inline-block font-medium text-sm !py-1`}
                value={"Low Risk"}
              />
            </div>
          </div>
        </div>
        {/* Individual Shareholders */}
        <div className="border-b border-secondary_grey py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">
              Individual Shareholders
            </p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setOrganisationData({});
                setShowEditOrganisationPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="space-y-1">
              <p className="text-xs font-semibold">Name</p>
              <p>Name 1</p>
              <p className="text-xs text-light_grey font-semibold">
                +65 9348 9923
              </p>
              <p className="text-xs text-light_grey font-semibold">
                name1@gmail.com
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-xs font-semibold">Role:</p>
              <p className="text-xs text-light_grey font-semibold">
                Company Admin
              </p>
              <p className="text-xs text-light_grey font-semibold">Director</p>
              <p className="text-xs text-light_grey font-semibold">
                Shareholder
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">
                Identity Verification Status
              </p>
              <Badge
                className={`text-white border-success bg-success inline-block font-medium text-sm !py-1`}
                value={"Approved"}
              />
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">KYC Status</p>
              <Badge
                className={`border-success text-success inline-block font-medium text-sm !py-1`}
                value={"Low Risk"}
              />
            </div>
          </div>
        </div>
        {/* Corporate Shareholders */}
        <div className="py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">
              Corporate Shareholders
            </p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setOrganisationData({});
                setShowEditOrganisationPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="space-y-1">
              <p className="text-xs font-semibold">Company Name:</p>
              <p>Name 1</p>
              <p className="text-xs text-light_grey font-semibold">
                +65 9348 9923
              </p>
              <p className="text-xs text-light_grey font-semibold">
                name1@gmail.com
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-xs font-semibold">Role:</p>
              <p className="text-xs text-light_grey font-semibold">
                Company Admin
              </p>
              <p className="text-xs text-light_grey font-semibold">Director</p>
              <p className="text-xs text-light_grey font-semibold">
                Shareholder
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">
                Identity Verification Status
              </p>
              <Badge
                className={`border-secondary_light_shades bg-secondary_light_shades inline-block font-medium text-sm !py-1`}
                value={"Pending"}
              />
            </div>
            <div className="space-y-2">
              <p className="text-xs font-semibold">KYC Status</p>
              <Badge
                className={`border-secondary_light_shades inline-block font-medium text-sm !py-1`}
                value={"Pending"}
              />
            </div>
          </div>
        </div>
      </div>
      {/* User List */}
      <div className="bg-light_secondary_shades rounded-xl px-8">
        <div className="py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">Users</p>
            <button
              className="text-theme border-b border-theme"
              //   onClick={() => {
              //     setCapitalPopup(true);
              //   }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-4 gap-5">
            <div className="space-y-1">
              <p className="text-xs font-semibold">Name:</p>
              <p>Name 1</p>
              <p className="text-xs text-light_grey font-semibold">
                +65 9348 9923
              </p>
              <p className="text-xs text-light_grey font-semibold">
                name1@gmail.com
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-xs font-semibold">Role:</p>
              <p className="text-xs text-light_grey font-semibold">Preparer</p>
              <p className="text-xs text-light_grey font-semibold">Approver</p>
            </div>

            <div className="space-y-2">
              <p className="text-xs font-semibold">Status</p>
              <Badge
                className={`text-white border-success bg-success inline-block font-medium text-sm !py-1`}
                value={"Active"}
              />
            </div>
          </div>
        </div>
      </div>
      <AddButton value="Add User" />
      {showEditOrganisationPopup && (
        <EditOrganisationPopup
          organisationData={organisationData}
          setShowEditOrganisationPopup={setShowEditOrganisationPopup}
        />
      )}
    </>
  );
};

export default Organisation;
