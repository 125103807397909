import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Plus } from "../../assets/svg/AllSvg";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import AllRequest from "./request/AllRequest";

const ServiceRequest = () => {
  const [selectedPriority, setSelectedPriority] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Service Request
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Service Request"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded space-y-4 py-5 px-6">
        <div className="flex justify-between">
          <p className="font-ebGaramond text-2xl mb-3">
            EI Biz Service Request
          </p>
          <button
            className="btn-theme flex items-center uppercase"
            // onClick={() => {
            //   navigate("/create-edit-staff-account");
            // }}
          >
            <Plus className="mr-2" /> New Request
          </button>
        </div>
        <div className="flex justify-between">
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
          <div className="flex max-w-xs w-full gap-3">
            <ReactSelect
              className={"w-full max-w-md text-black p-0"}
              id="priorityDropdown"
              placeholder="Priority"
              options={[
                {
                  label: "Low Priority",
                  value: "low_priority",
                },
                {
                  label: "Mid Priority",
                  value: "mid_priority",
                },
                {
                  label: "High Priority",
                  value: "high_priority",
                },
              ]}
              isLoading={false}
              getOptionValue={(selectedPriority) => selectedPriority.value}
              value={selectedPriority}
              onChange={(e) => {
                setSelectedPriority(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="period"
              placeholder="Period"
              options={[
                { label: "This Week", value: "this_week" },
                { label: "This Month", value: "this_month" },
                { label: "All", value: "all" },
              ]}
              isLoading={false}
              getOptionValue={(selectedPeriod) => selectedPeriod.value}
              value={selectedPeriod}
              onChange={(e) => {
                setSelectedPeriod(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
        </div>
        <AllRequest />
      </div>
    </div>
  );
};

export default ServiceRequest;
