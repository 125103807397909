import React from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import Tab from "../../../commons/Tab";
import CompanyInformation from "./CompanyInformation";
import Organisation from "./Organisation";
import Documents from "./Documents";
import SalesTransaction from "./SalesTransaction";

const CompanyProfile = () => {
  const tabs = [
    { label: 'Company Information', content: <CompanyInformation /> },
    { label: 'Organisation', content: <Organisation /> },
    { label: 'Documents', content: <Documents /> },
    { label: 'Sales & Transaction', content: <SalesTransaction /> },
  ];
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          View Company
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"View Company"}
          breadCrumbParent2={"Companies"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <h1 className="font-ebGaramond font-semibold text-4xl mb-5">Company Profile</h1>
        <Tab tabs={tabs} btnClass="w-full font-semibold mb-5" />
      </div>
    </div>
  );
};

export default CompanyProfile;
