import React, { useState } from "react";
import { Close } from "../../assets/svg/AllSvg";
import { TextareaWithLabel } from "../../components/micro";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ConfirmationModel from "../../commons/ConfirmationModel";

const EditRemark = ({ setShowEditRemarks, remarkData }) => {
  const [confirmSave, setConfirmSave] = useState(false);

  return (
    <>
      <div
        className="model"
        onClick={() => {
          setShowEditRemarks(false);
        }}
      >
        <div
          className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-6 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            className="absolute top-5 right-5"
            onClick={() => {
              setShowEditRemarks(false);
            }}
          >
            <Close />
          </button>
          <h2 className="text-2xl font-ebGaramond border-b border-secondary_grey pb-4 mb-4">
            Edit Remark
          </h2>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                remarks: remarkData?.remark ?? "",
              }}
              validationSchema={Yup.object().shape({
                remarks: Yup.string().required("Please Enter Remarks"),
              })}
              onSubmit={(values) => {
                setConfirmSave(true);
                console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="space-y-3">
                  <TextareaWithLabel
                    className={
                      errors.remarks && touched.remarks
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="remark"
                    label="Remark"
                    row={1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remarks}
                    errors={errors.remarks && touched.remarks}
                    errorsText={errors.remarks}
                  />
                  <button type="submit" className="btn-theme w-full">
                    Save
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save Remarks"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            setConfirmSave(false);
          }}
        />
      )}
    </>
  );
};

export default EditRemark;
