import React, { useState } from "react";
import { DownArrow, UpArrow } from "../assets/svg/AllSvg";

const DropdownCheckbox = ({
  label,
  children,
  buttonClass,
  form,
  isOpen,
  indexOnChange,
  inputChecked,
  inputNameId,
}) => {
  const [open, setOpen] = useState(isOpen ?? false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="relative">
        <label form={form}>
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={toggleDropdown}
              className={buttonClass}
            >
              <input
                type="checkbox"
                id={inputNameId}
                name={inputNameId}
                className="w-4 h-4 mr-2"
                checked={inputChecked}
                onChange={indexOnChange}
                onClick={(e) => {
                  e.stopPropagation()
                  setOpen(true)
                }}
              />
              <p>{label}</p>
            </button>
            {open ? <UpArrow /> : <DownArrow />}
          </div>
        </label>
        {open && <div className="">{children}</div>}
      </div>
    </>
  );
};

export default DropdownCheckbox;
