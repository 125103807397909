import React from "react";

const Toggles = ({ toggleFor, ...props }) => {
  return (
    <>
      <label
        htmlFor={toggleFor}
        className="relative inline-flex items-center cursor-pointer"
      >
        <input
          type="checkbox"
          id={toggleFor}
          className="sr-only peer"
          {...props}
        />
        <div className="w-[34px] h-3 bg-[#9e9e9e] rounded-full peer peer-checked:bg-[#83abcc]"></div>
        <div className="absolute left-0 top-1/2 bg-white w-5 h-5 rounded-full -translate-y-1/2 transition-transform shadow-toggle peer-checked:bg-theme peer-checked:translate-x-3/4"></div>
      </label>
    </>
  );
};

export default Toggles;
