import React, { useState } from "react";
import FolderDocuments from "../createEditCompany/FolderDocuments";
import FolderCard from "../../../commons/FolderCard";
import { AddButton } from "../../../components/micro";

const Documents = () => {
  const [showPDFDocuments, setShowPDFDocuments] = useState(false);
  const [folderDetails, setFolderDetails] = useState({});
  const folderList = [
    {
      folderName: "Folder Name 1",
      lastUpdated: "24/03/2024 14:00pm",
    },
    {
      folderName: "Folder Name 2",
      lastUpdated: "24/03/2024 14:00pm",
    },
    {
      folderName: "Folder Name 3",
      lastUpdated: "24/03/2024 14:00pm",
    },
    {
      folderName: "Folder Name 4",
      lastUpdated: "24/03/2024 14:00pm",
    },
    {
      folderName: "Folder Name 5",
      lastUpdated: "24/03/2024 14:00pm",
    },
  ];
  return (
    <>
      {showPDFDocuments ? (
        <FolderDocuments
          folderDetails={folderDetails}
          setShowPDFDocuments={setShowPDFDocuments}
          setFolderDetails={setFolderDetails}
        />
      ) : (
        <>
          <div className="grid 2xl:grid-cols-8 grid-cols-6 gap-5 bg-light_secondary rounded-lg px-8 py-5">
            {folderList?.map((element, index) => {
              return (
                <FolderCard
                  folderName={element?.folderName}
                  lastUpdated={element?.lastUpdated}
                  key={index}
                  onClick={() => {
                    setFolderDetails(element);
                    setShowPDFDocuments(true);
                  }}
                />
              );
            })}
          </div>
          <AddButton value="Add Folder" />
        </>
      )}
      <div className="flex justify-end gap-4 pt-3">
        <button type="button" className="btn-outline uppercase">
          Back
        </button>
        <button type="submit" className="btn-theme uppercase">
          Submit & Next
        </button>
      </div>
    </>
  );
};

export default Documents;
