import React from "react";
import {
  DefaultPhoneInput,
  InputWithLabel,
} from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import { Form, Formik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";

const PersonalDetails = () => {
  const individual_kyc_statusList = [
    { value: "role", label: "Role" },
    { value: "roleOne", label: "Role One" },
    { value: "roleTwo", label: "Role Two" },
  ];
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          personalId: "",
          fullName: "",
          email: "",
          mobile: "",
          individual_kyc_status: "",
        }}
        validationSchema={Yup.object().shape({
          personalId: Yup.string().min(3).required("Please Enter Personal Id"),
          fullName: Yup.string().min(3).required("Please Enter Full Name"),
          email: Yup.string().min(3).required("Please Enter E-mail"),
          mobile: Yup.string()
            .required("please Enter Mobile")
            .max(13, "Mobile is too long - should be 10 chars maximum."),
          individual_kyc_status: Yup.object().required(
            "Please Select Individual KYC Status"
          ),
        })}
        onSubmit={(values) => {
          console.log(values, "values");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-6 mb-4">
              {/* personalId */}
              <InputWithLabel
                className={
                  errors.personalId && touched.personalId
                    ? ` input-error `
                    : ` border `
                }
                idFromName="personalId"
                label="Personal ID (Auto-Generated)"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.personalId}
                errors={errors.personalId && touched.personalId}
                errorsText={errors.personalId}
              />
              {/* Name */}
              <InputWithLabel
                className={
                  errors.fullName && touched.fullName
                    ? ` input-error `
                    : ` border `
                }
                idFromName="fullName"
                label="Full Name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullName}
                errors={errors.fullName && touched.fullName}
                errorsText={errors.fullName}
              />
              {/* Mobile */}
              <div>
                <div
                  className={`${
                    errors.mobile && touched.mobile
                      ? ` input-error `
                      : ` border `
                  } relative bg-white border-secondary_grey transition duration-300 rounded plus-number-inputs`}
                >
                  <DefaultPhoneInput
                    className={`${
                      errors.mobile && touched.mobile ? "error-forms" : ""
                    } rounded p-3`}
                    value={values.mobile}
                    onBlur={handleBlur("mobile")}
                    name={"mobile"}
                    max={10}
                    onChange={(value) => {
                      value !== undefined
                        ? setFieldValue("mobile", value)
                        : setFieldValue("mobile", "");
                    }}
                  />
                  <label
                    htmlFor="mobile"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Mobile
                  </label>
                </div>
                {errors.mobile && touched.mobile ? (
                  <InputError errorTitle={errors.mobile} />
                ) : null}
              </div>
              {/* Email */}
              <InputWithLabel
                className={
                  errors.email && touched.email ? ` input-error ` : ` border `
                }
                idFromName="email"
                label="Email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errors={errors.email && touched.email}
                errorsText={errors.email}
              />
              {/* Individual KYC Status */}
              <div>
                <div
                  className={`${
                    errors.individual_kyc_status &&
                    touched.individual_kyc_status
                      ? ` input-error `
                      : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="individual_kyc_status"
                    placeholder="Select Individual KYC Status"
                    options={individual_kyc_statusList}
                    isLoading={false}
                    getOptionValue={(individual_kyc_statusList) =>
                      individual_kyc_statusList.value
                    }
                    value={values.individual_kyc_status}
                    onChange={(e) => {
                      setFieldValue("individual_kyc_status", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.individual_kyc_status &&
                          touched.individual_kyc_status
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="individual_kyc_status"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Individual KYC Status
                  </label>
                </div>
                {errors.individual_kyc_status &&
                touched.individual_kyc_status ? (
                  <InputError errorTitle={errors.individual_kyc_status} />
                ) : null}
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button type="button" className="btn-outline uppercase">
                Cancel
              </button>
              <button type="submit" className="btn-theme uppercase">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalDetails;
