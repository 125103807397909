import React, { useState } from "react";
import { Form, Formik } from "formik";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateMasterSetting } from "../../../redux/masterSetting/slice";
import ConfirmationModel from "../../../commons/ConfirmationModel";

const NomineeDirector = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: value,
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/service-settings");
      }
    });
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          months_3: masterSettingData?.detail?.months_3 ?? "",
          months_6: masterSettingData?.detail?.months_6 ?? "",
          year_1: masterSettingData?.detail?.year_1 ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          months_3: Yup.string().required("Please Enter 3 Months Price"),
          months_6: Yup.string().required("Please Enter 6 Months Price"),
          year_1: Yup.string().required("Please Enter 1 Months Price"),
          remarks: Yup.string().required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-5 items-center">
              {/* 3 Months */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="months_3"
              >
                3 Months
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"months_3"}
                  className={
                    errors.months_3 && touched.months_3
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.months_3}
                  errors={errors.months_3 && touched.months_3}
                  errorsText={errors.months_3}
                />
              </div>
              {/* 6 Months */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="months_6"
              >
                6 Months
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"months_6"}
                  className={
                    errors.months_6 && touched.months_6
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.months_6}
                  errors={errors.months_6 && touched.months_6}
                  errorsText={errors.months_6}
                />
              </div>
              {/* 1 Year */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="year_1"
              >
                1 Year
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"year_1"}
                  className={
                    errors.year_1 && touched.year_1
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.year_1}
                  errors={errors.year_1 && touched.year_1}
                  errorsText={errors.year_1}
                />
              </div>
              {/* Remarks */}
              <div className="col-span-4">
                <TextareaWithLabel
                  className={
                    errors.remarks && touched.remarks
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  idFromName="remarks"
                  label="Remarks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remarks}
                  errors={errors.remarks && touched.remarks}
                  errorsText={errors.remarks}
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn uppercase"
                onClick={() => {
                  navigate("/service-settings");
                }}
              >
                Cancel
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default NomineeDirector;
