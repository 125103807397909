import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Role List
export const getRoleList = createAsyncThunk(
  "getRoleList",
  async ({ search, pageLimit, pageCount }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `roles?search=${search}&page=${pageCount}&limit=${pageLimit}`
      );
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get All Role List
export const getAllRoleList = createAsyncThunk(
  "getAllRoleList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("get-roles");
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Permissions List
export const getPermissionsList = createAsyncThunk(
  "getPermissionsList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`permissions/${id ?? ""}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Edit Role
export const createRole = createAsyncThunk(
  "createRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`roles`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Role
export const deleteRole = createAsyncThunk(
  "deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`roles/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialStates,
  extraReducers: {
    // get Role List
    [getRoleList.pending]: (state) => {
      state.loading = true;
    },
    [getRoleList.fulfilled]: (state, action) => {
      state.loading = false;
      state.getRoleListStatus.data = action.payload;
      state.error = false;
    },
    [getRoleList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Permissions List
    [getPermissionsList.pending]: (state) => {
      state.loading = true;
    },
    [getPermissionsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.getPermissionsListStatus.data = action.payload;
      state.error = false;
    },
    [getPermissionsList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Create Role
    [createRole.pending]: (state) => {
      state.loading = true;
    },
    [createRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.createRoleStatus.data = action.payload;
      state.error = false;
    },
    [createRole.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Delete Role
    [deleteRole.pending]: (state) => {
      state.loading = true;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteRoleStatus.data = action.payload;
      state.error = false;
    },
    [deleteRole.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Get All Role List
    [getAllRoleList.pending]: (state) => {
      state.getAllRoleListStatus.loading = true;
    },
    [getAllRoleList.fulfilled]: (state, action) => {
      state.getAllRoleListStatus.loading = false;
      state.getAllRoleListStatus.data = action.payload;
      state.getAllRoleListStatus.error = false;
    },
    [getAllRoleList.rejected]: (state) => {
      state.getAllRoleListStatus.loading = false;
      state.getAllRoleListStatus.error = true;
    },
  },
});
const { reducer } = accountsSlice;
export default reducer;
