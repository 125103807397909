import React from "react";
import ReactSelect from "react-select";
import { Trash } from "../assets/svg/AllSvg";
import { Input } from "../components/micro";

const NewShareholder = ({
  element,
  primary_activityList,
  index,
  UpdateNewShareholder,
  removeNewShareholder
}) => {
  return (
    <div>
      <div className="border-b border-secondary_grey py-5">
        <div className="flex items-center justify-between mb-3">
          <p>New Shareholder</p>
          <button onClick={() => removeNewShareholder(index)}>
            <Trash />
          </button>
        </div>
        {/* Share Name */}
        <div className="mb-5">
          <div
            className={`relative bg-white transition duration-300 rounded plus-number-inputs`}
          >
            <ReactSelect
              className={"text-black p-0"}
              id="share_name"
              placeholder="share_name"
              options={primary_activityList}
              isLoading={false}
              value={element.share_name}
              onChange={(e) => {
                UpdateNewShareholder(
                  e,
                  element.total_number_of_shares,
                  index
                );
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #0000003B`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            <label
              htmlFor="share_name"
              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Share Name
            </label>
          </div>
        </div>
        {/* Total Number of Shares */}
        <div>
          <div
            className={`border relative bg-white border-secondary_grey transition duration-300 rounded`}
          >
            <Input
              type="test"
              id="total_number_of_shares"
              name="total_number_of_shares"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder={" "}
              onChange={(e) => {
                UpdateNewShareholder(element.share_name, e.target.value, index);
              }}
              value={element.total_number_of_shares}
            />
            <label
              htmlFor="total_number_of_shares"
              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Total Number of Shares
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShareholder;
