import React, { useState } from "react";
import DebounceSearch from "../../../commons/DebounceSearch";
import ReactSelect from "react-select";
import CustomTable from "../../../commons/CustomTable";
import Action from "../../../commons/Action";
import { useNavigate } from "react-router-dom";

const SalesTransaction = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("sales");
  const [selectedType, setSelectedType] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const tabs = [{ label: "sales" }, { label: "Points Transaction" }];

  const typesDropdown = [];
  const yearDropdown = [];

  // Table Data
  const sales_transactionData = {
    data: [
      {
        id: "S0001",
        points_type: "Name 1",
        date_time: "00/00/000 00:00 PM",
        amount: "5000",
      },
      {
        id: "S0001",
        points_type: "Name 1",
        date_time: "00/00/000 00:00 PM",
        amount: "5000",
      },
      {
        id: "S0001",
        points_type: "Name 1",
        date_time: "00/00/000 00:00 PM",
        amount: "5000",
      },
      {
        id: "S0001",
        points_type: "Name 1",
        date_time: "00/00/000 00:00 PM",
        amount: "5000",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 4,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl-xl"
        >
          ID
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Points Type
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Date | Time
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Amount
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr-xl"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(sales_transactionData?.data || [])?.map((element) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">{element?.id}</td>
            <td className="px-5 py-3">{element?.points_type}</td>
            <td className="px-5 py-3">{element?.date_time}</td>
            <td className="px-5 py-3">{element?.amount}</td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/create-edit-customers", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                      Archive
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="space-y-5">
      <div className="border p-3 rounded-lg">
        <div className="flex border border-theme rounded overflow-hidden">
          {tabs.map((tab, index) => (
            <button
              key={tab.label}
              className={`py-2 px-4 uppercase text-center text-theme font-semibold w-full transition duration-300 ${
                activeTab === tab.label ? "bg-theme/30" : ""
              } ${index === 0 && "border-r border-theme"}`}
              onClick={() => setActiveTab(tab.label)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-3">
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
          <ReactSelect
            className={"w-full max-w-xs text-black p-0"}
            id="typesDropdown"
            placeholder="Select Type"
            options={typesDropdown}
            isLoading={false}
            getOptionValue={(selectedType) => selectedType.value}
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
        </div>
        <ReactSelect
          className={"w-full max-w-xs text-black p-0"}
          id="year"
          placeholder="Select Year"
          options={yearDropdown}
          isLoading={false}
          getOptionValue={(selectedYear) => selectedYear.value}
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(e);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
      </div>
      <div className="w-full bg-white rounded-xl border overflow-hidden">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={sales_transactionData}
          overflowVisibleLg={true}
          // loader
          loading={false}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={sales_transactionData?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
    </div>
  );
};

export default SalesTransaction;
