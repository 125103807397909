// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getSettingStatus: { data: null },
  getMasterSettingStatus: { data: null },
  updateMasterSettingStatus: { data: null },
  getMasterSettingByIdStatus: { data: null },
  updateSubscriptionRemarksStatus: { data: null },
  updateSubscriptionStatusStatus: { data: null },
  getSettingsTypeStatus: { data: null },
};
