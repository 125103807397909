import React from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import Tab from "../../../commons/Tab";
import General from "./General";
import Subscription from "./Subscription";

const GeneralSetting = () => {
  const tabs = [
    { label: "General", content: <General /> },
    { label: "Subscription", content: <Subscription /> },
  ];
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          General Setting
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"General Setting"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full pt-3 bg-white border rounded flex justify-end">
        <Tab
          tabs={tabs}
          rightSide={true}
          btnDivClass="pr-6"
          btnClass="font-semibold mb-5"
        />
      </div>
    </div>
  );
};

export default GeneralSetting;
