import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../components/micro";
import Toggles from "../../commons/Toggles";
import Breadcrumb from "../../commons/Breadcrumb";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import { FileDownload, Plus } from "../../assets/svg/AllSvg";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import { useDispatch, useSelector } from "react-redux";
import { companiesList, employmentSupport } from "../../redux/companies/slice";
import RippleLoader from "../../commons/RippleLoader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Companies = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [showConfirmArchivePopup, setShowConfirmArchivePopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selector
  const { companiesListData, loading } = useSelector((store) => ({
    companiesListData: store?.companiesData?.companiesListStatus?.data?.data,
    loading: store?.companiesData?.loading,
  }));

  useEffect(() => {
    dispatch(
      companiesList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        status: activeTab === "archived" ? "inactive" : activeTab,
      })
    );
  }, [dispatch, searchValue, pageCount, pageLimit, activeTab]);

  // Active and Archived Tab
  const tabs = [
    {
      label: "active",
    },
    { label: "archived" },
  ];

  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "tagged", label: "Tagged" },
    { value: "untagged", label: "Untagged" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  // Update Status
  const updateStatus = (element) => {
    dispatch(
      employmentSupport({
        id: element?.id,
        data: {
          status: element?.status === "inactive" ? "active" : "inactive",
        },
      })
    ).then((res) => {
      if (res.type === "employmentSupport/fulfilled") {
        dispatch(
          companiesList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            status: activeTab === "archived" ? "inactive" : activeTab,
          })
        );
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          ID
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Company Name
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Email
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Mobile
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          KYC
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Tag Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(companiesListData?.data || [])?.map((element) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">{element?.company_id}</td>
            <td className="px-5 py-3">{element?.name}</td>
            <td className="px-5 py-3">{element?.email}</td>
            <td className="px-5 py-3">{element?.mobile}</td>
            <td className="px-5 py-3">
              {
                <div className="flex justify-start">
                  <Badge
                    className={`capitalize ${
                      element?.kyc_status === "low_risk"
                        ? "border-green-700 text-green-700"
                        : element?.kyc_status === "mid_risk"
                        ? "border-orange-700 text-orange-700"
                        : element?.kyc_status === "high_risk"
                        ? "border-red-700 text-red-700"
                        : element?.kyc_status === "pending"
                        ? "text-black bg-secondary_light_shades"
                        : ""
                    }`}
                    value={
                      element?.kyc_status === "low_risk"
                        ? "Low Risk"
                        : element?.kyc_status === "mid_risk"
                        ? "Mid Risk"
                        : element?.kyc_status === "high_risk"
                        ? "High Risk"
                        : element?.kyc_status === "pending"
                        ? "Pending"
                        : ""
                    }
                  />
                </div>
              }
            </td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize ${
                      element?.tagStatus === "tagged"
                        ? "border-green-700 bg-green-700"
                        : element?.tagStatus === "untagged"
                        ? "border-red-700 bg-red-700"
                        : ""
                    }`}
                    value={element?.tagStatus}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">
              <Toggles
                toggleFor={element?.id}
                checked={element?.status === "active"}
                onChange={() => {
                  updateStatus(element);
                }}
              />
            </td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/view-company", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setCompanyData(element);
                        setShowConfirmArchivePopup(true);
                      }}
                    >
                      Archive
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="space-y-5">
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
              Companies
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Companies"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-company");
            }}
          >
            <Plus className="mr-2" /> Add company
          </button>
        </div>
        <div className="flex justify-end">
          {tabs.map((tab, index) => {
            return (
              <button
                key={tab.label}
                className={`transition uppercase duration-300 ${
                  index === 0 ? "!rounded-l !rounded-r-none" : ""
                }
            ${index === tabs.length - 1 ? "!rounded-r !rounded-l-none" : ""} ${
                  activeTab === tab.label ? "btn-theme" : "btn-light_primary"
                }`}
                onClick={() => setActiveTab(tab.label)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <div className="flex justify-between">
          <div className="flex items-center space-x-3">
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="tagStatusDropdown"
              placeholder="Select Tag Status"
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="kyc"
              placeholder="Select KYC"
              options={kycDropdown}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={`Companies`}
            sheet={`Companies`}
            buttonText={
              <>
                <button className="btn-outline flex items-center uppercase">
                  <FileDownload width="14" height="17" className="mr-3" />
                  Export CSV
                </button>
              </>
            }
          />
        </div>
        <div className="w-full bg-white border rounded">
          {loading ? (
            <RippleLoader />
          ) : (
            <CustomTable
              columnHeaders={<ColumnHeaders />}
              dataRows={<DataRows />}
              data={companiesListData}
              overflowVisibleLg={true}
              // loader
              loading={false}
              showPagination={true}
              // showLimit
              setPaginationCurrentLimit={setPageLimit}
              paginationCurrentLimit={pageLimit}
              // paginationData
              total={companiesListData?.total}
              current={pageCount}
              paginationData={(crPage) => setPageCount(crPage)}
            />
          )}
        </div>
      </div>
      {showConfirmArchivePopup && (
        <div
          className="model"
          onClick={() => {
            setShowConfirmArchivePopup(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-2xl font-ebGaramond mb-4">Confirm Archive?</h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setShowConfirmArchivePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  setShowConfirmArchivePopup(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <table id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Company Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">KYS</th>
            <th scope="col">Tag Status</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {companiesListData?.data?.map((element) => {
            return (
              <tr>
                <td>{element?.company_id}</td>
                <td>{element?.name}</td>
                <td>{element?.email}</td>
                <td>{element?.mobile}</td>
                <td>Card</td>
                <td>
                  {element?.kyc_status === "low_risk"
                    ? "Low Risk"
                    : element?.kyc_status === "mid_risk"
                    ? "Mid Risk"
                    : element?.kyc_status === "high_risk"
                    ? "High Risk"
                    : element?.kyc_status === "pending"
                    ? "Pending"
                    : ""}
                </td>
                <td>{element?.tagStatus}</td>
                <td>{element?.status === "active" ? "Active" : "Inactive"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Companies;
