import React, { useEffect, useState } from "react";
import { Edit, Plus, Trash } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../commons/Breadcrumb";
import CustomTable from "../../commons/CustomTable";
import DebounceSearch from "../../commons/DebounceSearch";
import ViewPermissionList from "./ViewPermissionList";
import AddEditRole from "./AddEditRole";
import { useDispatch, useSelector } from "react-redux";
import { deleteRole, getRoleList } from "../../redux/accounts/slice";
import RippleLoader from "../../commons/RippleLoader";
import { toast } from "react-toastify";
import ConfirmationModel from "../../commons/ConfirmationModel";

const RoleAccess = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [viewPermissionListPopup, setViewPermissionListPopup] = useState(false);
  const [addEditRolePopup, setAddEditRolePopup] = useState(false);
  const [viewPermissionData, setViewPermissionData] = useState({});
  const [deleteRoleLoad, setDeleteRoleLoad] = useState(false);

  const dispatch = useDispatch();

  // Selector
  const { roleList, roleLoading } = useSelector((store) => ({
    // profile
    roleList: store?.accountsData?.getRoleListStatus?.data,
    roleLoading: store?.accountsData?.loading,
  }));

  useEffect(() => {
    dispatch(
      getRoleList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
      })
    );
  }, [dispatch, pageCount, pageLimit, searchValue]);

  // Delete Role
  const handleDeleteRole = () => {
    setDeleteRoleLoad(true);
    dispatch(deleteRole(viewPermissionData?.id)).then((res) => {
      setDeleteRoleLoad(false);
      if (res.type === "deleteRole/fulfilled") {
        toast.success(res?.payload?.message);
        dispatch(
          getRoleList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
          })
        );
        setConfirmDelete(false);
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Role Name
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Permission List
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Actions
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(roleList?.data?.data || [])?.map((element, index) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">
              {(roleList?.data?.current_page - 1) * roleList?.data?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3">{element?.title}</td>
            <td className="px-5 py-3">
              <button
                className="text-theme border-b border-theme"
                type="button"
                onClick={() => {
                  setViewPermissionListPopup(true);
                  setViewPermissionData(element);
                }}
              >
                View
              </button>
            </td>
            <td className="px-4 py-2">
              <div className="flex items-center space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setAddEditRolePopup(true);
                    setViewPermissionData(element);
                  }}
                >
                  <Edit />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setConfirmDelete(true);
                    setViewPermissionData(element);
                  }}
                >
                  <Trash />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="flex justify-between items-start mb-5">
        <div>
          <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
            Role & Access
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Role & Access"}
            breadCrumbParent2={"Accounts"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <button
          className="btn-theme flex items-center"
          onClick={() => {
            setViewPermissionData({});
            setAddEditRolePopup(true);
          }}
        >
          <Plus className="mr-2" /> Add Role
        </button>
      </div>
      <div className="mb-5">
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
      </div>
      {roleLoading ? (
        <RippleLoader />
      ) : (
        <div className="w-full bg-white border rounded overflow-hidden">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            overflowVisibleLg={true}
            data={roleList?.data}
            // loader
            loading={roleLoading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={roleList?.data?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      )}
      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={deleteRoleLoad}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={handleDeleteRole}
        />
      )}
      {viewPermissionListPopup && (
        <ViewPermissionList
          viewPermissionData={viewPermissionData}
          setViewPermissionListPopup={setViewPermissionListPopup}
          setAddEditRolePopup={setAddEditRolePopup}
        />
      )}
      {addEditRolePopup && (
        <AddEditRole
          viewPermissionData={viewPermissionData}
          setAddEditRolePopup={setAddEditRolePopup}
          searchValue={searchValue}
          pageCount={pageCount}
          pageLimit={pageLimit}
        />
      )}
    </>
  );
};

export default RoleAccess;
