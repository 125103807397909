import React from "react";
import { PDFDoc } from "../assets/svg/AllSvg";
import Action from "./Action";

const PdfDocument = ({ documentName, uploadTime, cardClasses }) => {
  return (
    <div className={`card bg-light_secondary space-y-2 ${cardClasses}`}>
      <div className={"flex justify-between items-center"}>
        <div className="flex items-center">
          <PDFDoc className="mr-3" />
          <p>{documentName}</p>
        </div>
        <Action
          optionList={
            <>
              <li className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                View
              </li>
              <li className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                Download
              </li>
            </>
          }
        />
      </div>
      {uploadTime && (
        <p className="text-center text-light_grey font-semibold text-xs leading-normal">
          Uploaded on <span className="block">“{uploadTime}”</span>
        </p>
      )}
    </div>
  );
};

export default PdfDocument;
