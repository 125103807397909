import React, { useState } from "react";
import { Logo } from "../../../../assets/image";
import LocalResidentDirector from "./LocalResidentDirector";
import NomineeDirector from "./NomineeDirector";
import { useSelector } from "react-redux";

const DirectorsShareholders = () => {
  const [activeTab, setActiveTab] = useState(0);

  // Selector
  const { CreateCompany } = useSelector((store) => ({
    CreateCompany: store?.companiesData?.CreateCompanyStatus?.data?.data,
  }));

  const tabs = [
    {
      text: "Use my local resident director ",
      id: 0,
      content: <LocalResidentDirector company_id={CreateCompany?.id} />,
      link: "Eligibility Criteria",
    },
    {
      text: "Use EiBIZ's Nominee Director",
      subText:
        "If you are based overseas or need a time buffer to get a local director",
      icon: true,
      id: 1,
      content: <NomineeDirector company_id={CreateCompany?.id} />,
    },
  ];

  return (
    <div>
      <div className="border-b border-secondary_grey pb-2 mb-5">
        <p className="text-md mb-1">Thirdly,</p>
        <p className="font-ebGaramond text-2xl mb-3">Invite company members</p>
      </div>
      {/* tab head */}
      <div className="grid grid-cols-2 gap-5 mb-5">
        {tabs?.map((element) => {
          return (
            <div
              className={`p-4 ${
                activeTab === element?.id ? "bg-light_theme" : "bg-white"
              } cursor-pointer rounded-lg border-2 space-y-3`}
              onClick={() => {
                setActiveTab(element?.id);
              }}
            >
              <div className="flex space-x-5">
                {element?.icon && (
                  <img
                    src={Logo}
                    className="w-11 h-11 object-contain"
                    alt="icon"
                  />
                )}
                <div className="space-y-3 w-full">
                  <p>{element?.text}</p>
                  {element?.subText && (
                    <p className="text-sm text-gray-400">{element?.subText}</p>
                  )}
                </div>
              </div>
              {element?.link && (
                <button className="text-theme border-b border-theme">
                  {element?.link}
                </button>
              )}
            </div>
          );
        })}
      </div>
      {/* tab content */}
      {tabs?.map((element) => activeTab === element?.id && element?.content)}
    </div>
  );
};

export default DirectorsShareholders;
