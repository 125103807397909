import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../assets/image";

const SidebarDropDown = ({ isAsideOpen, menu }) => {
  const [subDrop, setSubDrop] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <li className={`group aside-links `}>
        <button
          className={`flex items-center relative z-[99999] tooltipBlock ${
            !isAsideOpen
              ? "px-0.5 hover:translate-x-0 my-1 justify-center"
              : " hover:translate-x-1 px-4"
          } py-2 gap-x-4 h-auto  hover:text-black w-full rounded-lg transition duration-200`}
          onClick={() => {
            setSubDrop(!subDrop);
          }}
        >
          <div className="flex items-center">
            <img src={menu?.icon_path} className="" alt="icon" />
            {!isAsideOpen && <ArrowDown2 size="16" />}
          </div>
          <span
            className={`flex items-center justify-between w-full font-medium text-left ${
              !isAsideOpen && "hidden"
            }`}
          >
            {menu?.title}
            <span className="">
              {subDrop ? <ArrowUp2 size="16" /> : <ArrowDown2 size="16" />}
            </span>
          </span>
          {!isAsideOpen && (
            <div
              id="tooltip"
              className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
            >
              <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
              <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px] flex items-center">
                {menu?.title}
              </div>
            </div>
          )}
        </button>
        <ul
          className={`${
            subDrop ? ` ` : ` w-0 h-0 overflow-hidden `
          } transition duration-300 `}
        >
          {menu?.subMenu?.map((element, index) => {
            return (
              <li
                className={`text-sm flex items-center gap-x-4 cursor-pointer p-2 rounded-md w-full  ${
                  location.pathname === `/${element?.path}` &&
                  `bg-light_secondary_shades`
                } ${
                  isAsideOpen
                    ? "px-4 mx-2 py-2.5 hover:translate-x-1"
                    : "hover:bg-light_secondary_shades hover:text-black justify-center"
                } transition duration-200 my-1 tooltipBlock`}
                onClick={() => {
                  navigate(element?.path);
                }}
                key={index}
              >
                <div
                  className={`font-medium flex gap-x-4 items-center relative`}
                >
                  <div className="max-w-5 max-h-5 w-full">
                    <img
                      src={element?.icon_path ?? Logo}
                      className="w-full h-full object-contain"
                      alt="icon"
                    />
                  </div>
                  <p className={`${!isAsideOpen && "hidden"}`}>
                    {element?.title}
                  </p>
                  {!isAsideOpen && (
                    <div
                      id="tooltip"
                      className="right absolute top-[0%] left-[40px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
                    >
                      <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                      <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                        {element?.title}
                      </div>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </li>
    </>
  );
};

export default SidebarDropDown;
