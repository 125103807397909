import React from "react";
import { BackArrow, Folder } from "../assets/svg/AllSvg";

const BackButton = ({ className, onClick, text, icon }) => {
  return (
    <div
      className={`flex items-center space-x-3 ${className}`}
      onClick={onClick}
    >
      <BackArrow />
      <div className="flex items-center space-x-2">
        {icon}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default BackButton;
