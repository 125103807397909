import React from "react";
import { InputWithLabel } from "../../components/micro";
import { InputError } from "../../commons/MicroComponents";
import ReactSelect from "react-select";

const SubscriptionField = ({ element,
    UpdateSubscription,
    index,
    setFieldValue,
    error_subscription,
    error_subscription_pricing,}) => {
    const subscription_options = [
        {
          label: "Both",
          value: "both",
        },
        {
          label: "Book Keeping",
          value: "book_keeping",
        },
        {
          label: "Corporate Secretary",
          value: "corporate_secretary",
        },
      ];
  return (
    <>
      <div className="grid grid-cols-2 gap-5 w-full">
        {/* Subscription */}
        <div>
          <div
            className={`relative bg-white transition duration-300 rounded plus-number-inputs`}
          >
            <ReactSelect
              className={"text-black p-0"}
              id="subscription"
              placeholder="Select Subscription"
              options={subscription_options}
              getOptionValue={(subscription_options) =>
                subscription_options.value
              }
              value={element.subscription}
              onChange={(e) => {
                UpdateSubscription(e, element.pricing, index);
                setFieldValue("subscription", e.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid ${
                    error_subscription ? "red" : "#0000003B"
                  }`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            <label
              htmlFor="subscription"
              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Subscription
            </label>
          </div>
          {error_subscription && <InputError errorTitle={error_subscription} />}
        </div>
        {/* Pricing ($) */}
        <InputWithLabel
          className={error_subscription_pricing ? "input-error" : "border"}
          idFromName="pricing"
          label="Pricing ($)"
          type="text"
          onChange={(e) => {
            UpdateSubscription(element.subscription, e.target.value, index);
            setFieldValue("subscription_pricing", e.target.value);
          }}
          value={element.pricing}
          errors={error_subscription_pricing}
          errorsText={error_subscription_pricing}
        />
      </div>
    </>
  );
};

export default SubscriptionField;
