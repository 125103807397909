import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../commons/MicroComponents";
import { Badge, Input } from "../../../components/micro";
import ReactSelect from "react-select";

const EditAddressCompanyActivities = ({ setAddressCompanyActivitiesPopup }) => {
  const primary_activityList = [
    { value: "system_one", label: "system One" },
    { value: "system_two", label: "system Two" },
  ];
  return (
    <div className="model" onClick={(e)=>{
      setAddressCompanyActivitiesPopup(false)
    }}>
      <div className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative" onClick={(e)=>{
      e.stopPropagation()
    }}>
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Registered Office Address & Company Activities
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            address: "",
            floor: "",
            unit: "",
            country: "",
            postal_code: "",
            primary_activity: "",
            secondary_activity: "",
          }}
          validationSchema={Yup.object().shape({
            address: Yup.string().min(3).required("Please Enter Address"),
            floor: Yup.string().min(3).required("Please Enter Floor"),
            unit: Yup.string().min(3).required("Please Enter Unit"),
            country: Yup.object().required("Please Select Country"),
            postal_code: Yup.string()
              .min(3)
              .required("Please Enter Postal Code"),
            primary_activity: Yup.object().required(
              "Please Select Primary Activity"
            ),
            secondary_activity: Yup.object().required(
              "Please Select Secondary Activity"
            ),
          })}
          onSubmit={(values) => {
            console.log(values, "values");
            setAddressCompanyActivitiesPopup(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="w-full max-h-96 overflow-y-auto" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">Company Pte Ltd</p>
                <Badge
                  className={`text-white border-success bg-success font-medium text-sm !py-1`}
                  value={"Live"}
                />
              </div>
              <div className="space-y-5">
                {/* Address */}
                <div>
                  <div
                    className={`${
                      errors.address && touched.address
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="address"
                      name="address"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    <label
                      htmlFor="address"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Address
                    </label>
                  </div>
                  {errors.address && touched.address ? (
                    <InputError errorTitle={errors.address} />
                  ) : null}
                </div>
                <div className="grid grid-cols-2 gap-3">
                  {/* floor */}
                  <div>
                    <div
                      className={`${
                        errors.floor && touched.floor
                          ? ` input-error `
                          : ` border `
                      } relative bg-white border-secondary_grey transition duration-300 rounded`}
                    >
                      <Input
                        type="test"
                        id="floor"
                        name="floor"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder={" "}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.floor}
                      />
                      <label
                        htmlFor="floor"
                        className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Floor
                      </label>
                    </div>
                    {errors.floor && touched.floor ? (
                      <InputError errorTitle={errors.floor} />
                    ) : null}
                  </div>
                  {/* Unit */}
                  <div>
                    <div
                      className={`${
                        errors.unit && touched.unit
                          ? ` input-error `
                          : ` border `
                      } relative bg-white border-secondary_grey transition duration-300 rounded`}
                    >
                      <Input
                        type="test"
                        id="unit"
                        name="unit"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder={" "}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.unit}
                      />
                      <label
                        htmlFor="unit"
                        className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Unit
                      </label>
                    </div>
                    {errors.unit && touched.unit ? (
                      <InputError errorTitle={errors.unit} />
                    ) : null}
                  </div>
                </div>
                {/* Country */}
                <div>
                  <div
                    className={`${
                      errors.country && touched.country ? ` input-error ` : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="country"
                      placeholder="Country"
                      options={primary_activityList}
                      isLoading={false}
                      getOptionValue={(primary_activityList) =>
                        primary_activityList.value
                      }
                      value={values.country}
                      onChange={(e) => {
                        setFieldValue("country", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.country && touched.country
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="country"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Country
                    </label>
                  </div>
                  {errors.country && touched.country ? (
                    <InputError errorTitle={errors.country} />
                  ) : null}
                </div>
                {/* Postal Code */}
                <div>
                  <div
                    className={`${
                      errors.postal_code && touched.postal_code
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="postal_code"
                      name="postal_code"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_code}
                    />
                    <label
                      htmlFor="postal_code"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Postal Code
                    </label>
                  </div>
                  {errors.postal_code && touched.postal_code ? (
                    <InputError errorTitle={errors.postal_code} />
                  ) : null}
                </div>
                {/* Primary Activity */}
                <div>
                  <div
                    className={`${
                      errors.primary_activity && touched.primary_activity
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="primary_activity"
                      placeholder="Primary Activity"
                      options={primary_activityList}
                      isLoading={false}
                      getOptionValue={(primary_activityList) =>
                        primary_activityList.value
                      }
                      value={values.primary_activity}
                      onChange={(e) => {
                        setFieldValue("primary_activity", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.primary_activity && touched.primary_activity
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="primary_activity"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Primary Activity
                    </label>
                  </div>
                  {errors.primary_activity && touched.primary_activity ? (
                    <InputError errorTitle={errors.primary_activity} />
                  ) : null}
                </div>
                {/* Secondary Activity */}
                <div>
                  <div
                    className={`${
                      errors.secondary_activity && touched.secondary_activity
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="secondary_activity"
                      placeholder="Secondary Activity"
                      options={primary_activityList}
                      isLoading={false}
                      getOptionValue={(primary_activityList) =>
                        primary_activityList.value
                      }
                      value={values.secondary_activity}
                      onChange={(e) => {
                        setFieldValue("secondary_activity", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.secondary_activity &&
                            touched.secondary_activity
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="secondary_activity"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Secondary Activity
                    </label>
                  </div>
                  {errors.secondary_activity && touched.secondary_activity ? (
                    <InputError errorTitle={errors.secondary_activity} />
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4 w-full pt-5">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setAddressCompanyActivitiesPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme w-full uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditAddressCompanyActivities;
