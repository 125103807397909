import React from "react";
import BackButton from "../../../commons/BackButton";
import { FileUpload, Folder } from "../../../assets/svg/AllSvg";
import PdfDocument from "../../../commons/PdfDocument";
import FileUploadChoose from "../../../commons/FileUploadChoose";

const FolderDocuments = ({
  folderDetails,
  setShowPDFDocuments,
  setFolderDetails,
}) => {
  const documentList = [
    {
      documentName: "pdfname.pdf",
      uploadTime: "24/03/2024 14:00pm",
    },
    {
      documentName: "pdfname.pdf",
      uploadTime: "24/03/2024 14:00pm",
    },
  ];

  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const handleFileChange = (e) => {
    const fileData = e.target.files[0];
    const totalChunks = Math.ceil(fileData.size / CHUNK_SIZE);
    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileData.size);
      const fileChunk = fileData.slice(start, end);
      console.log("----------------", ((i + 1) / totalChunks) * 100);
    }
  };
  return (
    <>
      <div className="bg-light_secondary rounded-2xl px-6 py-4 mb-5">
        <BackButton
          className="cursor-pointer mb-5"
          onClick={() => {
            setFolderDetails({});
            setShowPDFDocuments(false);
          }}
          icon={<Folder width="20" height="20" />}
          text={folderDetails?.folderName}
        />
        <div className="grid grid-cols-6 gap-5">
          {documentList?.map((element, index) => {
            return (
              <PdfDocument
                documentName={element?.documentName}
                uploadTime={element?.uploadTime}
                key={index}
                cardClasses="bg-white"
              />
            );
          })}
        </div>
      </div>
      <FileUploadChoose handleFileChange={handleFileChange} />
    </>
  );
};

export default FolderDocuments;
