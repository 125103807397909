import React from "react";
import { Folder, MoreVert } from "../assets/svg/AllSvg";

const FolderCard = ({folderName, lastUpdated, onClick}) => {
  return (
    <>
      <div className="card relative bg-white !rounded-xl text-center cursor-pointer" onClick={onClick}>
        <Folder className="mb-4" />
        <button className="absolute top-4 right-4 p-1">
          <MoreVert onClick={(e)=>{
e.stopPropagation();
          }}/>
        </button>
        <p className="mb-1">{folderName}</p>
        <p className="text-xs text-light_grey font-semibold leading-normal">
          Last Updated “{lastUpdated}”
        </p>
      </div>
    </>
  );
};

export default FolderCard;
