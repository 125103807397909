import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Form, Formik } from "formik";
import { Input, InputWithLabel } from "../../components/micro";
import { InputError } from "../../commons/MicroComponents";
import * as Yup from "yup";
import ReactSelect from "react-select";
import Toggles from "../../commons/Toggles";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateEditBroadcast = () => {
  const [scheduled, setScheduled] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [broadcastData, setBroadcastData] = useState({});
  const user_typeList = [
    {
      label: "User Type",
      value: "user_type",
    },
    {
      label: "User Type One",
      value: "user_type_one",
    },
  ];

  const ConfirmBroadcast = (broadcastData) => {
    console.log(broadcastData, "broadcastData");
  };

  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Create Broadcast
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Broadcast"}
          breadCrumbParent2={"Broadcast"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <Formik
          enableReinitialize={true}
          initialValues={{
            subject: "",
            content: "",
            user_type: "",
            date_time: "",
          }}
          validationSchema={Yup.object().shape({
            subject: Yup.string().min(3).required("Please Enter Subject"),
            content: Yup.string().min(3).required("Please Enter Content"),
            user_type: Yup.object().required("Please Select User Type"),
            date_time: scheduled
              ? Yup.string().nullable().required("Please Select Date & Time")
              : "",
          })}
          onSubmit={(values) => {
            setIsConfirm(true);
            setBroadcastData(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="space-y-5 mb-5">
                {/* Subject */}
                <InputWithLabel
                  className={
                    errors.subject && touched.subject
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="subject"
                  label="Subject"
                  type="subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                  errors={errors.subject && touched.subject}
                  errorsText={errors.subject}
                />
                {/* Content */}
                <InputWithLabel
                  className={
                    errors.content && touched.content
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="content"
                  label="Content"
                  type="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  errors={errors.content && touched.content}
                  errorsText={errors.content}
                />
                {/* User Type (Subscription Tier) */}
                <div>
                  <div
                    className={`${
                      errors.user_type && touched.user_type
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="user_type"
                      placeholder="Select User Type (Subscription Tier)"
                      options={user_typeList}
                      isLoading={false}
                      getOptionValue={(user_typeList) => user_typeList.value}
                      value={values.user_type}
                      onChange={(e) => {
                        setFieldValue("user_type", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.user_type && touched.user_type
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="user_type"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      User Type (Subscription Tier)
                    </label>
                  </div>
                  {errors.user_type && touched.user_type ? (
                    <InputError errorTitle={errors.user_type} />
                  ) : null}
                </div>
                {/* Scheduled */}
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3">
                    <p className="text-light_grey">Scheduled?</p>
                    <Toggles
                      checked={scheduled}
                      toggleFor={"scheduled"}
                      onChange={() => {
                        setScheduled(!scheduled);
                      }}
                    />
                  </div>
                  {/* Date & Time */}
                  {scheduled && (
                    <div className="max-w-sm w-full">
                      <div
                        className={`${
                          errors.date_time && touched.date_time
                            ? ` input-error `
                            : ""
                        } relative bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactDatePicker
                          selected={values.date_time}
                          onChange={(date) => setFieldValue("date_time", date)}
                          dateFormat="dd/MM/yyyy HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          selectsStart
                          timeIntervals={15}
                          timeCaption="time"
                          id="date_time"
                          placeholderText={"Select Date & Time"}
                          className={`border error-forms ${
                            errors.date_time && touched.date_time
                              ? "border-red"
                              : "border-secondary_grey"
                          } rounded-lg w-full px-4 py-3`}
                        />
                        <label
                          htmlFor="date_time"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Date & Time
                        </label>
                      </div>
                      {errors.date_time && touched.date_time ? (
                        <InputError errorTitle={errors.date_time} />
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end gap-4">
                <button type="button" className="btn-outline uppercase">
                  Cancel
                </button>
                <button type="submit" className="btn-theme uppercase">
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isConfirm && (
        <div
          className="model"
          onClick={() => {
            setIsConfirm(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-2xl font-ebGaramond max-w-[200px] text-center mb-4">
              Confirm Create Broadcast?
            </h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setIsConfirm(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  ConfirmBroadcast(broadcastData);
                  setIsConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEditBroadcast;
