import React from "react";
import { Icon } from "../../assets/svg/AllSvg";
import CustomTable from "../../commons/CustomTable";
import { Link } from "react-router-dom";
import AllGood from "../../commons/AllGood";

const Tickets = () => {
  const ticketsData = {
    data: [
      {
        ticketName: "Ticket Title",
      },
      {
        ticketName: "Ticket Title",
      },
      {
        ticketName: "Ticket Title",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
  };

  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Tickets
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Actions
        </th>
      </>
    );
  };
  const DataRows = () => {
    return (
      <>
        {(ticketsData?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b text-sm bg-light_secondary hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3">
              {(ticketsData?.current_page - 1) * ticketsData?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3">{element?.ticketName}</td>
            <td className="px-5 py-3">
              <Link to={"/"} className="text-theme border-b border-theme">
                View
              </Link>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <p className="text-lg flex items-center font-ebGaramond font-semibold mb-2">
        <Icon className="fill-theme mr-2" width={24} /> Tickets
      </p>
      {ticketsData?.data?.length > 0 ? (
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={ticketsData}
          overflowVisibleLg={false}
          // loader
          loading={false}
          showPagination={false}
        />
      ) : (
        <AllGood />
      )}
      <div className="text-right mt-5">
        <Link
          to={"/"}
          className="text-theme border-b border-theme text-sm font-bold uppercase text-right w-full"
        >
          View All
        </Link>
      </div>
    </>
  );
};

export default Tickets;
