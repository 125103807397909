import React from "react";

const TableShowLimit = ({
  paginationCurrentLimit,
  setPaginationCurrentLimit,
}) => {
  return (
    <>
      <div className="flex items-center">
        <label
          htmlFor="pagination"
          className="text-xs opacity-70 mr-2"
        >
          Rows per page:
        </label>
        <div className="text-xs">
          <select
            name="pagination"
            id="pagination"
            defaultValue={paginationCurrentLimit}
            onChange={(e) => {
              setPaginationCurrentLimit(e.target.value);
            }}
            className="px-1"
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default TableShowLimit;
