import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "../layouts";
import Dashboard from "../pages/dashboard/Dashboard";
import Auth from "../pages/auth/Auth";
import Profile from "../pages/userDetails/Profile";
import Page404 from "../commons/Page404";
import Page500 from "../commons/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import PasswordSuccessful from "../pages/auth/PasswordSuccessful";
import RoleAccess from "../pages/accounts/RoleAccess";
import StaffAccounts from "../pages/accounts/StaffAccounts";
import CreateEditStaffAccount from "../pages/accounts/CreateEditStaffAccount";
import Customers from "../pages/customers/Customers";
import CreateEditCustomers from "../pages/customers/createEditCustomers/CreateEditCustomers";
import Notifications from "../pages/notifications/Notifications";
import Companies from "../pages/companies/Companies";
import CreateEditCompany from "../pages/companies/createEditCompany/CreateEditCompany";
import Broadcast from "../pages/broadcast/Broadcast";
import CreateEditBroadcast from "../pages/broadcast/CreateEditBroadcast";
import Sales from "../pages/sales/Sales";
import CompanyProfile from "../pages/companies/viewCompany/CompanyProfile";
import ServiceSetting from "../pages/masterSetting/serviceSetting/ServiceSetting";
import EditSetting from "../pages/masterSetting/serviceSetting/EditSetting";
import GeneralSetting from "../pages/masterSetting/generalSetting/GeneralSetting";
import Accounting from "../pages/masterSetting/serviceSetting/Accounting";
import ActivityLog from "../pages/activityLog/ActivityLog";
import CreateServiceRequest from "../pages/sales/CreateServiceRequest";
import CreateSubscription from "../pages/sales/CreateSubscription";
import CreateBoth from "../pages/sales/CreateBoth";
import Chat from "../pages/chat/Chat";
import SalesView from "../pages/sales/SalesView";
import EditSubscription from "../pages/masterSetting/generalSetting/EditSubscription";
import ServiceRequest from "../pages/serviceRequest/ServiceRequest";
import Tickets from "../pages/tickets/Tickets";

const AppRoutes = () => {
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" />
      <Routes>
        {/* Auth */}
        <Route exact path="/login" status={401} element={<Auth />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password/:email/:token" element={<NewPassword />} />
        <Route path="/password-successful" element={<PasswordSuccessful />} />

        {/* DefaultLayout */}
        <Route path="/" element={<DefaultLayout />}>
          {/* Dashboard */}
          <Route index exact element={<Dashboard />} />
          {/* Accounts */}
          <Route exact path="/role-access" element={<RoleAccess />} />
          <Route exact path="/staff-accounts" element={<StaffAccounts />} />
          <Route
            exact
            path="/create-edit-staff-account"
            element={<CreateEditStaffAccount />}
          />
          {/* Profile */}
          <Route exact path="/profile" element={<Profile />} />
          {/* Customers */}
          <Route exact path="/customers" element={<Customers />} />
          <Route
            exact
            path="/create-edit-customers"
            element={<CreateEditCustomers />}
          />
          {/* Companies */}
          <Route exact path="/companies" element={<Companies />} />
          <Route
            exact
            path="/create-edit-company"
            element={<CreateEditCompany />}
          />
          <Route exact path="/view-company" element={<CompanyProfile />} />
          {/* Notifications */}
          <Route exact path="/notifications" element={<Notifications />} />
          {/* Broadcast */}
          <Route exact path="/broadcast" element={<Broadcast />} />
          <Route
            exact
            path="/create-edit-broadcast"
            element={<CreateEditBroadcast />}
          />
          {/* Sales */}
          <Route exact path="/sales" element={<Sales />} />
          <Route exact path="/sales_view" element={<SalesView />} />
          <Route
            exact
            path="/create-service-request"
            element={<CreateServiceRequest />}
          />
          <Route
            exact
            path="/create-subscription"
            element={<CreateSubscription />}
          />
          <Route exact path="/create-both" element={<CreateBoth />} />
          {/* Master Setting */}
          <Route exact path="/general-settings" element={<GeneralSetting />} />
          <Route
            exact
            path="/edit-subscription"
            element={<EditSubscription />}
          />
          <Route exact path="/service-settings" element={<ServiceSetting />} />
          <Route exact path="/edit-service" element={<EditSetting />} />
          <Route exact path="/accounting" element={<Accounting />} />
          {/* Activity Log */}
          <Route exact path="/activity-log" element={<ActivityLog />} />
          {/* Chat */}
          <Route exact path="/chat" element={<Chat />} />
          {/* Service Request */}
          <Route exact path="/service-request" element={<ServiceRequest />} />
          {/* Tickets */}
          <Route exact path="/tickets" element={<Tickets />} />
          {/* Page 404 */}
          <Route
            exact
            path="*"
            name="Page 404"
            status={404}
            element={<Page404 />}
          />
          {/* Page 500 */}
          <Route
            exact
            path="/500"
            name="Page 500"
            status={500}
            element={<Page500 />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
