import React, { useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import CompanyInformation from "./companyInformation/CompanyInformation";
import Confirmation from "./Confirmation";
import { Provider } from "./CompanyContext";
import CompanyAddress from "./companyAddress/CompanyAddress";
import DirectorsShareholders from "./directorsShareholders/DirectorsShareholders";
import Accounting from "./accounting/Accounting";
import { CheckCircle } from "../../../assets/svg/AllSvg";

const CreateEditCompany = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [companyId, setCompanyId] = useState("");

  const tabLabel = [
    { value: "company_information", label: "Company Information" },
    { value: "company_address", label: "Company Address" },
    { value: "directors_shareholders", label: "Directors & Shareholders" },
    { value: "accounting", label: "Employment Support" },
    { value: "confirmation", label: "Confirmation" },
  ];

  const renderStep = (step) => {
    switch (
      tabLabel[step]?.value // added optional chaining to prevent undefined access
    ) {
      case "company_information":
        return (
          <CompanyInformation
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        );
      case "company_address":
        return <CompanyAddress companyId={companyId} />;
      case "directors_shareholders":
        return <DirectorsShareholders companyId={companyId} />;
      case "accounting":
        return <Accounting companyId={companyId} />;
      case "confirmation":
        return <Confirmation companyId={companyId} />;
      default:
        return null;
    }
  };

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Create Company
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Company"}
          breadCrumbParent2={"Companies"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="">
        <Provider value={{ next, prev }}>
          <div className="grid grid-cols-6 gap-5">
            <div className="stepper space-y-10">
              {tabLabel?.map((step, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <span
                    className={`max-w-8 w-full h-8 rounded-full flex justify-center items-center bg-gray-400 text-white ${
                      index < currentStep ? "!bg-white text-green-700" : ""
                    } ${index === currentStep ? "!bg-theme" : ""}`}
                  >
                    {index < currentStep ? (
                      <CheckCircle
                        className="w-8 h-8"
                        pathClass={"fill-green-700"}
                      />
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p
                    className={` ${
                      index < currentStep ? "text-green-700" : ""
                    } ${index === currentStep ? "font-semibold" : ""}`}
                  >
                    {step.label}
                  </p>
                </div>
              ))}
            </div>
            <div className="col-span-5 bg-white border rounded py-5 px-6">
              {renderStep(currentStep)}
            </div>
          </div>
        </Provider>
      </div>
    </>
  );
};

export default CreateEditCompany;
