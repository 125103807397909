import React from "react";
import ReactApexChart from "react-apexcharts";

const Cart = ({ series }) => {
  const state = {
    series: series,
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#1795FF",
        strokeDashArray: 7,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        horizontalAlign: "left",
        markers: {
          radius: 12,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " ";
          },
        },
      },
    },
  };
  return (
    <div>
      <div>
        <div id="chart">
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={200}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default Cart;
