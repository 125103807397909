import React, { useState } from "react";

const Tab = ({ tabs, btnClass, rightSide, btnDivClass }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  return (
    <div className="w-full">
      <div className={`flex ${btnDivClass} ${rightSide ? "justify-end" : ""}`}>
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`py-2 px-4 uppercase border-b-2 transition duration-300 ${
              activeTab === tab.label
                ? "border-theme text-theme"
                : "border-transparent text-light_grey hover:text-theme hover:border-theme"
            } ${btnClass}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>{tabs?.map((tab) => activeTab === tab.label && tab.content)}</div>
    </div>
  );
};

export default Tab;
