import React, { useState } from "react";
import {
  FileDownload,
  ImgIcon,
  PDFFileIcon,
  UpArrow,
} from "../../assets/svg/AllSvg";

const Documents = () => {
  const [isOpen, setIsOpen] = useState(true);
  const files = [
    {
      file_name: "File 1",
      file_type: "pdf",
    },
    {
      file_name: "File 2",
      file_type: "img",
    },
  ];
  return (
    <div className="px-4">
      <div className="bg-secondary_light_shades rounded-2xl h-full space-y-3 py-4 px-5">
        <div className="flex space-x-2">
          <p className="text-dark_primary">Documents</p>
          <span className="w-6 h-6 bg-green-600 text-white rounded-full flex justify-center items-center">
            2
          </span>
        </div>
        <button
          className="flex justify-between items-center w-full"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <p className="text-theme">Files</p>
          {isOpen ? <UpArrow /> : <UpArrow className="rotate-180" />}
        </button>
        {isOpen && (
          <>
            {files.map((element, index) => {
              return (
                <div className="flex justify-between items-center" key={index}>
                  <div className="flex items-center space-x-2">
                    {element?.file_type === "img" ? (
                      <ImgIcon />
                    ) : (
                      <PDFFileIcon />
                    )}
                    <p>{element?.file_name}</p>
                  </div>
                  <button>
                    <FileDownload />
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Documents;
