// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getPermissionsListStatus: { data: null },
  getRoleListStatus: { data: null },
  deleteRoleStatus: { data: null },
  createRoleStatus: { data: null },
  getAllRoleListStatus: { loading: false, error: false, data: null },
};
