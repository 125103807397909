import React from "react";
import PdfDocument from "../../../commons/PdfDocument";
import FileUploadChoose from "../../../commons/FileUploadChoose";

const Documents = () => {
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const handleFileChange = (e) => {
    const fileData = e.target.files[0];
    const totalChunks = Math.ceil(fileData.size / CHUNK_SIZE);
    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileData.size);
      const fileChunk = fileData.slice(start, end);
      console.log(fileChunk, "----------------", ((i + 1) / totalChunks) * 100);
    }
  };
  const documentList = [
    {
      documentName: "pdfname.pdf",
      uploadTime: "24/03/2024 14:00pm",
    },
    {
      documentName: "pdfname.pdf",
      uploadTime: "24/03/2024 14:00pm",
    },
  ];

  return (
    <>
      <div className="py-5">
        <FileUploadChoose handleFileChange={handleFileChange} />
      </div>
      <div className="grid grid-cols-5 gap-5">
        {documentList?.map((element, index) => {
          return (
            <PdfDocument
              documentName={element?.documentName}
              uploadTime={element?.uploadTime}
              key={index}
            />
          );
        })}
      </div>
    </>
  );
};

export default Documents;
