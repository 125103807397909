import React, { useEffect, useState } from "react";
import { InputWithLabel } from "../../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  getMasterSetting,
  updateMasterSetting,
} from "../../../redux/masterSetting/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../../commons/RippleLoader";
import Toggles from "../../../commons/Toggles";
import ConfirmationModel from "../../../commons/ConfirmationModel";

const General = () => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [values, setValues] = useState({});

  const dispatch = useDispatch();

  // Selector
  const { asterSettingData, asterSettingDataLoading } = useSelector(
    (store) => ({
      // profile
      asterSettingData: store?.masterSettingData?.getMasterSettingStatus?.data,
      asterSettingDataLoading: store?.masterSettingData?.loading,
    })
  );

  useEffect(() => {
    dispatch(getMasterSetting("gst"));
  }, [dispatch]);

  const handelSubmit = (value) => {
    const payload = {
      id: asterSettingData?.id,
      details: {
        gst: value.gst,
      },
      status: values.status ? "active" : "inactive",
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        dispatch(getMasterSetting("gst"));
      }
    });
  };

  return (
    <div className="px-6 pb-5">
      {asterSettingDataLoading ? (
        <RippleLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            gst: asterSettingData?.detail?.gst ?? "",
            status: asterSettingData?.status === "active",
          }}
          validationSchema={Yup.object().shape({
            gst: Yup.number()
              .required("Please Enter GST (%)")
              .oneOf([100], "GST must be 100%"),
          })}
          onSubmit={(values) => {
            setValues(values);
            setConfirmSave(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="space-y-5">
              {/* GST */}
              <InputWithLabel
                label={"GST (%)"}
                type={"number"}
                idFromName={"gst"}
                className={
                  errors.gst && touched.gst ? ` input-error ` : ` border `
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.gst}
                errors={errors.gst && touched.gst}
                errorsText={errors.gst}
              />
              <div className="flex items-center space-x-3">
                <Toggles
                  checked={values.status}
                  value={values.status}
                  toggleFor={"status"}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFieldValue("status", isChecked);
                  }}
                />
                <p className="text-light_grey">
                  To remove the GST charge, simply switch it off
                </p>
              </div>
              <div className="flex justify-end gap-4">
                <button type="button" className="btn-outline uppercase">
                  Cancel
                </button>
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default General;
