import React, { useState } from "react";
import { Badge } from "../../../components/micro";
import { CompaniesIcon, Location, Star } from "../../../assets/svg/AllSvg";
import CompanyKYCStatus from "./CompanyKYCStatus";
import EditDatesToNote from "./EditDatesToNote";
import EditAddressCompanyActivities from "./EditAddressCompanyActivities";
import EditCapital from "./EditCapital";

const CompanyInformation = () => {
  const [companyKYCStatusPopup, setCompanyKYCStatusPopup] = useState(false);
  const [datesToNotePopup, setDatesToNotePopup] = useState(false);
  const [capitalPopup, setCapitalPopup] = useState(false);
  const [addressCompanyActivitiesPopup, setAddressCompanyActivitiesPopup] =
    useState(false);
  return (
    <>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <div className="col-span-2 card !rounded-xl !px-6 !py-5">
          <div className="flex items-center space-x-5">
            <CompaniesIcon className="fill-theme w-8 h-8" fillOpacity={1} />
            <div>
              <div className="flex items-center space-x-3 mb-2">
                <p className="text-xl font-ebGaramond">Company Pte Ltd</p>
                <Badge
                  className={`text-white border-success bg-success font-medium text-sm !py-1`}
                  value={"Live"}
                />
              </div>
              <Badge
                className={`border-success text-success font-medium inline-block text-sm !py-1 mb-3`}
                value={"Low Risk"}
              />
              <p className="text-xs text-light_grey font-semibold mb-1">
                Registration No.
              </p>
              <p className="text-xs mb-3">000000000X</p>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Incorporation Date
              </p>
              <p className="text-xs">DD/MM/YYY</p>
            </div>
          </div>
        </div>
        <div className="card !rounded-xl !px-6 !py-5">
          <div className="flex items-center space-x-5 h-full">
            <Star className="fill-theme w-8 h-8" />
            <div className="flex flex-col justify-between h-full">
              <p className="text-xl font-ebGaramond">Subscription</p>
              <div>
                <p className="text-xs text-light_grey font-semibold mb-1">
                  Tier
                </p>
                <p className="mb-3">VVIP</p>
              </div>
              <div>
                <p className="text-xs text-light_grey font-semibold mb-1">
                  Expiry Date
                </p>
                <p>22/04/2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light_secondary rounded-xl px-8">
        <div className="border-b border-secondary_grey py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">Company KYC</p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCompanyKYCStatusPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <p className="text-xs text-light_grey font-semibold mb-2">
            KYC Status
          </p>
          <Badge
            className={`border-success text-success font-medium inline-block text-sm !py-1 mb-3`}
            value={"Low Risk"}
          />
        </div>
        <div className="border-b border-secondary_grey py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">Dates to note:</p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setDatesToNotePopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-4">
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Financial Year End
              </p>
              <p>31 Dec 2023</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Annual General Meeting (AGM)
              </p>
              <p>31 Dec 2023</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Annual Return
              </p>
              <p>31 Dec 2023</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Tax Return (Form C/C-S)
              </p>
              <p>31 Dec 2023</p>
            </div>
          </div>
        </div>
        <div className="border-b border-secondary_grey py-5">
          <div className="flex justify-between mb-3">
            <p className="text-theme text-xl font-ebGaramond">Capital</p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCapitalPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-5">
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Share Name
              </p>
              <p>Customer 1</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Total Paid-up Capital
              </p>
              <p>1</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Total Issued Share Capital
              </p>
              <p>5000</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Total Number of Shares
              </p>
              <p>5000</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Currency
              </p>
              <p>SGD</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 py-5">
          <div>
            <p className="text-theme text-xl font-ebGaramond mb-3">
              Registered Office Address
            </p>
            <div className="flex items-start space-x-3">
              <Location />
              <div>
                <p className="text-xs text-light_grey font-semibold mb-1">
                  Address
                </p>
                <p>Blk 123 Riverdoor, #11-02 Singapore 334596</p>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex justify-between">
              <p className="text-theme text-xl font-ebGaramond">
                Company Activities
              </p>
              <button
                className="text-theme border-b border-theme"
                onClick={() => {
                  setAddressCompanyActivitiesPopup(true);
                }}
              >
                Edit
              </button>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Primary Activity
              </p>
              <p>[00000] Property</p>
            </div>
            <div>
              <p className="text-xs text-light_grey font-semibold mb-1">
                Secondary Activity
              </p>
              <p>[00000] Property</p>
            </div>
          </div>
        </div>
      </div>
      {companyKYCStatusPopup && (
        <CompanyKYCStatus setCompanyKYCStatusPopup={setCompanyKYCStatusPopup} />
      )}
      {datesToNotePopup && (
        <EditDatesToNote setDatesToNotePopup={setDatesToNotePopup} />
      )}
      {addressCompanyActivitiesPopup && (
        <EditAddressCompanyActivities
          setAddressCompanyActivitiesPopup={setAddressCompanyActivitiesPopup}
        />
      )}
      {capitalPopup && <EditCapital setCapitalPopup={setCapitalPopup} />}
    </>
  );
};

export default CompanyInformation;
