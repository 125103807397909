import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../commons/MicroComponents";
import { AddButton, Badge, Input } from "../../../components/micro";
import NewShareholder from "../../../commons/NewShareholder";

const EditCapital = ({ setCapitalPopup }) => {
  const [new_shareholder_list, setNew_shareholder_list] = useState([]);
  const primary_activityList = [
    { value: "system_one", label: "system One" },
    { value: "system_two", label: "system Two" },
  ];
  // Update New Shareholder
  const UpdateNewShareholder = (share_name, total_number_of_shares, index) => {
    const updatedShareholder = new_shareholder_list?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          share_name: share_name,
          total_number_of_shares: total_number_of_shares,
        };
      } else {
        return item;
      }
    });
    setNew_shareholder_list(updatedShareholder);
  };

  // Remove Account
  const removeNewShareholder = (index) => {
    setNew_shareholder_list([
      ...new_shareholder_list.slice(0, index),
      ...new_shareholder_list.slice(index + 1),
    ]);
  };
  return (
    <div
      className="model"
      onClick={(e) => {
        setCapitalPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Capital
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            share_name: "",
            total_paid_up_capital: "",
            total_issued_share_capital: "",
            total_number_of_shares: "",
            country: "",
            postal_code: "",
            primary_activity: "",
            secondary_activity: "",
          }}
          validationSchema={Yup.object().shape({
            share_name: Yup.string().min(3).required("Please Enter Share Name"),
            total_paid_up_capital: Yup.string()
              .min(3)
              .required("Please Enter Total Paid-up Capital"),
            total_issued_share_capital: Yup.string()
              .min(3)
              .required("Please Enter Total Issued Share Capital"),
            total_number_of_shares: Yup.string()
              .min(3)
              .required("Please Enter Total Number of Shares"),
            country: Yup.object().required("Please Select Country"),
            postal_code: Yup.string()
              .min(3)
              .required("Please Enter Postal Code"),
            primary_activity: Yup.object().required(
              "Please Select Primary Activity"
            ),
            secondary_activity: Yup.object().required(
              "Please Select Secondary Activity"
            ),
          })}
          onSubmit={(values) => {
            console.log(values, "values");
            setCapitalPopup(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              className="w-full max-h-96 overflow-y-auto"
              onSubmit={handleSubmit}
            >
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">Company Pte Ltd</p>
                <Badge
                  className={`text-white border-success bg-success font-medium text-sm !py-1`}
                  value={"Live"}
                />
              </div>
              <div className="space-y-5 pb-5 border-b border-secondary_grey">
                {/* Share Name */}
                <div>
                  <div
                    className={`${
                      errors.share_name && touched.share_name
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="share_name"
                      name="share_name"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.share_name}
                    />
                    <label
                      htmlFor="share_name"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Share Name
                    </label>
                  </div>
                  {errors.share_name && touched.share_name ? (
                    <InputError errorTitle={errors.share_name} />
                  ) : null}
                </div>
                {/* Total Paid-up Capital */}
                <div>
                  <div
                    className={`${
                      errors.total_paid_up_capital &&
                      touched.total_paid_up_capital
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="total_paid_up_capital"
                      name="total_paid_up_capital"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_paid_up_capital}
                    />
                    <label
                      htmlFor="total_paid_up_capital"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Total Paid-up Capital
                    </label>
                  </div>
                  {errors.total_paid_up_capital &&
                  touched.total_paid_up_capital ? (
                    <InputError errorTitle={errors.total_paid_up_capital} />
                  ) : null}
                </div>
                {/* Total Issued Share Capital */}
                <div>
                  <div
                    className={`${
                      errors.total_issued_share_capital &&
                      touched.total_issued_share_capital
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="total_issued_share_capital"
                      name="total_issued_share_capital"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_issued_share_capital}
                    />
                    <label
                      htmlFor="total_issued_share_capital"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Total Issued Share Capital
                    </label>
                  </div>
                  {errors.total_issued_share_capital &&
                  touched.total_issued_share_capital ? (
                    <InputError
                      errorTitle={errors.total_issued_share_capital}
                    />
                  ) : null}
                </div>
                {/* Total Number of Shares */}
                <div>
                  <div
                    className={`${
                      errors.total_number_of_shares &&
                      touched.total_number_of_shares
                        ? ` input-error `
                        : ` border `
                    } relative bg-white border-secondary_grey transition duration-300 rounded`}
                  >
                    <Input
                      type="test"
                      id="total_number_of_shares"
                      name="total_number_of_shares"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder={" "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_number_of_shares}
                    />
                    <label
                      htmlFor="total_number_of_shares"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Total Number of Shares
                    </label>
                  </div>
                  {errors.total_number_of_shares &&
                  touched.total_number_of_shares ? (
                    <InputError errorTitle={errors.total_number_of_shares} />
                  ) : null}
                </div>
              </div>
              {new_shareholder_list.map((element, index) => {
                return (
                  <NewShareholder
                    element={element}
                    primary_activityList={primary_activityList}
                    index={index}
                    UpdateNewShareholder={UpdateNewShareholder}
                    removeNewShareholder={removeNewShareholder}
                  />
                );
              })}
              <AddButton
                value="Add shareholder"
                onClick={(e) => {
                  setNew_shareholder_list([
                    ...new_shareholder_list,
                    {
                      share_name: "",
                      total_number_of_shares: "",
                    },
                  ]);
                }}
              />
              <div className="flex gap-4 w-full pt-5">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setCapitalPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme w-full uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditCapital;
