import React, { useState } from "react";
import { Logo, authBgImage } from "../../assets/image";
import RippleLoader from "../../commons/RippleLoader";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash } from "iconsax-react";
import { newPassword } from "../../redux/auth/slice";
import { useDispatch } from "react-redux";
import { InputWithLabel } from "../../components/micro";

const NewPassword = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const { email, token } = useParams();
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div className={`h-screen overflow-hidden relative`}>
        <div
          className={`flex items-center justify-center h-full  bg-no-repeat bg-cover bg-center`}
          style={{
            backgroundImage: `url(${authBgImage})`,
          }}
        >
          <div className="absolute top-10 left-10 w-20 h-20">
            <img src={Logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="rounded-lg drop-shadow-lg bg-white/80 px-6 py-8 mx-4">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-lg font-ebGaramond font-semibold">
                    New Password!
                  </h1>
                  <p className="text-sm opacity-65">Key in your new password</p>
                </div>
                {/* Login flow */}
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    role: "admin",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string().required(
                      "Please Enter New Password"
                    ),
                    confirmPassword: Yup.string()
                      .required("Please Enter Confirm New Password")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),
                  })}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(
                      newPassword({ email: email, code: token, ...values })
                    ).then((res) => {
                      setLoading(false);
                      if (res.type === "newPassword/fulfilled") {
                        navigate("/password-successful");
                      }
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        {/* New Password */}
                        <InputWithLabel
                          className={
                            errors.password && touched.password
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="password"
                          label="New Password"
                          type={passwordType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          errors={errors.password && touched.password}
                          errorsText={errors.password}
                          icon={
                            <p
                              className="p-2 absolute top-1.5 right-0 rounded cursor-pointer bg-transparent z-20"
                              onClick={() => {
                                if (passwordType === "password") {
                                  setPasswordType("text");
                                  return;
                                }
                                setPasswordType("password");
                              }}
                            >
                              {passwordType === "password" ? (
                                <EyeSlash size="20" />
                              ) : (
                                <>
                                  <Eye size="20" />
                                </>
                              )}
                            </p>
                          }
                        />
                        {/* Confirm New Password */}
                        <InputWithLabel
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="confirmPassword"
                          label="Confirm New Password"
                          type={confirmPasswordType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          errors={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          errorsText={errors.confirmPassword}
                          icon={
                            <p
                              className="p-2 absolute top-1.5 right-0 rounded cursor-pointer bg-transparent z-20"
                              onClick={() => {
                                if (confirmPasswordType === "password") {
                                  setConfirmPasswordType("text");
                                  return;
                                }
                                setConfirmPasswordType("password");
                              }}
                            >
                              {confirmPasswordType === "password" ? (
                                <EyeSlash size="20" />
                              ) : (
                                <>
                                  <Eye size="20" />
                                </>
                              )}
                            </p>
                          }
                        />
                        <button
                          type="submit"
                          disabled={loading}
                          className="w-full block uppercase text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                        >
                          {loading ? "loading..." : "Conform new password"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
