import React from "react";
import { Badge } from "../../../components/micro";
import ReactSelect from "react-select";
import moment from "moment/moment";

const AllRequest = () => {
  const requestList = [
    {
      name: "Service Name 1",
      current_status: "Billing Needed",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "High Priority",
      foreign: false,
    },
    {
      name: "Service Name 1",
      current_status: "Pending from Ei Biz",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "High Priority",
      foreign: true,
    },
    {
      name: "Service Name 1",
      current_status: "Expired",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "High Priority",
      foreign: false,
    },
    {
      name: "Service Name 1",
      current_status: "Pending from Client",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "Mid Priority",
      foreign: false,
    },
    {
      name: "Service Name 1",
      current_status: "Completed",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "Completed  ",
      foreign: false,
    },
    {
      name: "Service Name 1",
      current_status: "Cancelled",
      company_name: "Company Name",
      customer_name: "Customer Name",
      created_on: "",
      priority: "Cancelled",
      foreign: false,
    },
  ];
  return (
    <div className="space-y-5">
      {requestList?.map((data, index) => {
        return (
          <div
            className="hover:card border !rounded-lg space-y-3 p-4 hover:bg-light_secondary_shades"
            key={index}
          >
            <div className="flex items-center justify-between">
              <p className="text-xl font-ebGaramond text-theme">
                {data?.name} (New Request)
              </p>
              <div className="flex items-center gap-3">
                <p className="text-sm font-semibold text-light_grey">
                  Created on {moment().format("DD/MM/YYYY | HH:MM")}
                </p>
                {data?.priority === "High Priority" ? (
                  <Badge
                    value={data?.priority}
                    className={
                      "border-red-600 bg-red-600 text-white text-xs !px-2 !py-0.5"
                    }
                  />
                ) : data?.priority === "Mid Priority" ? (
                  <Badge
                    value={data?.priority}
                    className={
                      "border-orange-600 bg-orange-600 text-white text-xs !px-2 !py-0.5"
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <p className="text-sm text-light_grey">Current Status:</p>
              <Badge
                value={data?.current_status}
                className={`${
                  data?.current_status === "Billing Needed"
                    ? "border-orange-600 bg-orange-600 text-white"
                    : data?.current_status === "Expired"
                    ? "border-orange-600 text-orange-600"
                    : data?.current_status === "Pending from Client"
                    ? "border-theme text-light_green"
                    : data?.current_status === "Completed"
                    ? "text-light_green border-light_green"
                    : data?.current_status === "Cancelled"
                    ? "border-red-600 text-red-600"
                    : "border-theme text-theme"
                } 
                   text-xs !px-2 !py-0.5
                `}
              />
            </div>
            <div>
              <p className="text-theme">Company Name</p>
              <p className="text-theme text-sm">Customer Name</p>
            </div>
            <div>
              <p className="text-sm text-light_grey mb-1">Handled By:</p>
              <div className="flex justify-between items-end">
                <ReactSelect
                  className={"w-full max-w-xs text-black p-0"}
                  id="staffDropdown"
                  placeholder="Select Staff"
                  options={[
                    {
                      label: "Staff name 1",
                      value: "staff_name_1",
                    },
                    {
                      label: "Staff name 2",
                      value: "staff_name_2",
                    },
                  ]}
                  isLoading={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: `1px solid #cdddeb`,
                      borderRadius: "0.3rem",
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #cdddeb",
                      },
                    }),
                  }}
                />
                <button className="text-theme border-b border-theme">
                  Open Request
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllRequest;
