import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CheckBox, TextareaWithLabel } from "../../../../components/micro";

const CompanyAttractionsFunds = ({
  AttractionsData,
  setAttractionsSelectedArray,
  setAttractionsError,
}) => {
  const getInitialValues = (arr) => {
    let initial = {
      attractions_other: "",
    };

    arr?.map((element) => {
      initial[element.id] = false;
    });
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(AttractionsData),
  });

  useEffect(() => {
    let trueFieldKeys = Object.keys(formik.values).filter(
      (key) => formik.values[key]
    );
    // remove attractions other form
    trueFieldKeys = trueFieldKeys.filter(function (item) {
      return item !== "attractions_other";
    });

    setAttractionsSelectedArray({
      attractionsSelectedArray: trueFieldKeys,
      attractions_other: formik.values.attractions_other,
    });
    setAttractionsError("");
  }, [formik.values]);
  return (
    <>
      {AttractionsData?.map((element) => {
        return (
          <CheckBox
            idFrom={element.slug}
            label={element?.title}
            name={element.id}
            value={formik.values[element.id]}
            checked={formik.values[element.id]}
            onChange={formik.handleChange}
          />
        );
      })}
      {formik.values[1] && (
        <TextareaWithLabel
          idFromName={"attractions_other"}
          label={"Others"}
          className={"border"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.attractions_other}
        />
      )}
    </>
  );
};

export default CompanyAttractionsFunds;
