import React from "react";
import { Badge } from "../../../components/micro";
import Action from "../../../commons/Action";
import { User } from "../../../assets/svg/AllSvg";
import ReactSelect from "react-select";

const AllTickets = ({ setReplyTicketModel }) => {
  return (
    <div className="hover:card border !rounded-lg space-y-4 p-4 hover:bg-light_secondary_shades">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <span className="rounded-t-md bg-light_primary_50p text-white px-4 py-1">
            New
          </span>
          <p className="text-secondary">Ticket# 2023-CS123</p>
          <Badge
            value="High Priority"
            className={
              "border-red-600 bg-red-600 text-white text-xs !px-2 !py-0.5"
            }
          />
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-light_grey">Last Updated on 24/08/2023 | 13:00</p>
          <Action
            optionList={
              <>
                <li className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                  Archive
                </li>
              </>
            }
          />
        </div>
      </div>
      <div className="space-y-1">
        <p className="text-secondary">Subject here</p>
        <p className="text-light_grey">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
          dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit
          amet, consectetur adipiscing ...
        </p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 items-center max-w-xs w-full">
          <div className="max-w-8 w-full h-8 flex justify-center items-center bg-grey rounded-full">
            <User pathClass="fill-white" fillOpacity={1} />
          </div>
          <ReactSelect
            className={"w-full max-w-xs text-black p-0"}
            id="staffDropdown"
            placeholder="Select Staff"
            options={[
              {
                label: "Staff name 1",
                value: "staff_name_1",
              },
              {
                label: "Staff name 2",
                value: "staff_name_2",
              },
            ]}
            isLoading={false}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
        </div>
        <button
          className="text-theme border-b border-theme"
          onClick={() => {
            setReplyTicketModel(true);
          }}
        >
          Open Ticket
        </button>
      </div>
    </div>
  );
};

export default AllTickets;
