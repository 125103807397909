import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { InputError } from "../../../commons/MicroComponents";
import { Badge } from "../../../components/micro";

const CompanyKYCStatus = ({ setCompanyKYCStatusPopup }) => {
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];
  return (
    <div className="model" onClick={()=>{
      setCompanyKYCStatusPopup(false)
    }}>
      <div className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative" onClick={(e)=>{
      e.stopPropagation()
    }}>
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Company KYC Status
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            company_kyc: "",
          }}
          validationSchema={Yup.object().shape({
            company_kyc: Yup.object().required("Please Select Company KYC"),
          })}
          onSubmit={(values) => {
            console.log(values, "values");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">Company Pte Ltd</p>
                <Badge
                  className={`text-white border-success bg-success font-medium text-sm !py-1`}
                  value={"Live"}
                />
              </div>
              {/* Company Role */}
              <div className="w-full pb-3">
                <div
                  className={`${
                    errors.company_kyc && touched.company_kyc
                      ? ` input-error `
                      : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="company_kyc"
                    placeholder="Select Company Role"
                    options={kycDropdown}
                    isLoading={false}
                    getOptionValue={(kycDropdown) => kycDropdown.value}
                    value={values.company_kyc}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("company_kyc", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.company_kyc && touched.company_kyc
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="company_kyc"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Company Role
                  </label>
                </div>
                {errors.company_kyc && touched.company_kyc ? (
                  <InputError errorTitle={errors.company_kyc} />
                ) : null}
              </div>
              <div className="flex gap-4 w-full pt-3">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setCompanyKYCStatusPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme w-full uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CompanyKYCStatus;
