import React, { useState } from "react";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Toggles from "../../../commons/Toggles";
import { InputError } from "../../../commons/MicroComponents";
import { useNavigate } from "react-router-dom";
import { updateMasterSetting } from "../../../redux/masterSetting/slice";
import { useDispatch } from "react-redux";
import ConfirmationModel from "../../../commons/ConfirmationModel";

const AllotmentTransferShares = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Submit
  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: {
        free_request: value?.provide_free_request,
        no_of_free_request: value?.request_free,
        allotment: {
          local_shareholder: value?.local_shareholder,
          foreign_shareholder: value?.foreign_shareholder,
          stamp_duty: value?.stamp_duty,
          durations: value?.duration_allotment,
        },
        transfer: {
          local_shareholder: value?.local_shareholder_transfer,
          foreign_shareholder: value?.foreign_shareholder_transfer,
          stamp_duty: value?.stamp_duty_transfer,
          durations: value?.duration_transfer,
        },
      },
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/service-settings");
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          local_shareholder:
            masterSettingData?.detail?.allotment?.local_shareholder ?? "",
          foreign_shareholder:
            masterSettingData?.detail?.allotment?.foreign_shareholder ?? "",
          stamp_duty: masterSettingData?.detail?.allotment?.stamp_duty ?? "",
          duration_allotment:
            masterSettingData?.detail?.allotment?.durations ?? "",
          local_shareholder_transfer:
            masterSettingData?.detail?.transfer?.local_shareholder ?? "",
          foreign_shareholder_transfer:
            masterSettingData?.detail?.transfer?.foreign_shareholder ?? "",
          stamp_duty_transfer:
            masterSettingData?.detail?.transfer?.stamp_duty ?? "",
          duration_transfer:
            masterSettingData?.detail?.transfer?.durations ?? "",
          provide_free_request:
            masterSettingData?.detail?.free_request ?? false,
          request_free: masterSettingData?.detail?.no_of_free_request ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          local_shareholder: Yup.string().required(
            "Please Enter Local Shareholder Pricing"
          ),
          foreign_shareholder: Yup.string().required(
            "Please Enter Foreign Shareholder Pricing"
          ),
          stamp_duty: Yup.string().required("Please Enter Stamp Duty Pricing"),
          duration_allotment: Yup.string().required("Please Enter Duration"),
          local_shareholder_transfer: Yup.string().required(
            "Please Enter Local Shareholder Pricing"
          ),
          foreign_shareholder_transfer: Yup.string().required(
            "Please Enter Foreign Shareholder Pricing"
          ),
          stamp_duty_transfer: Yup.string().required(
            "Please Enter Stamp Duty Pricing"
          ),
          duration_transfer: Yup.string().required("Please Enter Duration"),
          provide_free_request: Yup.boolean().required(
            "Please Check Provide Free Request"
          ),
          remarks: Yup.string().min(15).required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className="space-y-3">
            <div className="grid grid-cols-4 gap-5 items-center">
              {/* Allotment */}
              <p>Allotment</p>
              {/* Local Shareholder Allotment */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="local_shareholder"
              >
                Local Shareholder
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"local_shareholder"}
                  className={
                    errors.local_shareholder && touched.local_shareholder
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.local_shareholder}
                  errors={errors.local_shareholder && touched.local_shareholder}
                  errorsText={errors.local_shareholder}
                />
              </div>
              {/* Foreign Shareholder Allotment */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="foreign_shareholder"
              >
                Foreign Shareholder
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"foreign_shareholder"}
                  className={
                    errors.foreign_shareholder && touched.foreign_shareholder
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.foreign_shareholder}
                  errors={
                    errors.foreign_shareholder && touched.foreign_shareholder
                  }
                  errorsText={errors.foreign_shareholder}
                />
              </div>
              {/* Stamp Duty Allotment */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="stamp_duty"
              >
                Stamp Duty
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"stamp_duty"}
                  className={
                    errors.stamp_duty && touched.stamp_duty
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stamp_duty}
                  errors={errors.stamp_duty && touched.stamp_duty}
                  errorsText={errors.stamp_duty}
                />
              </div>
              {/* Duration Allotment */}
              <div className="col-start-2 col-span-3">
                <InputWithLabel
                  label={"Duration (No. of Days)"}
                  type={"text"}
                  idFromName={"duration_allotment"}
                  className={
                    errors.duration_allotment && touched.duration_allotment
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.duration_allotment}
                  errors={
                    errors.duration_allotment && touched.duration_allotment
                  }
                  errorsText={errors.duration_allotment}
                />
              </div>
              {/* Transfer */}
              <p>Transfer</p>
              {/* Local Shareholder Transfer */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="local_shareholder_transfer"
              >
                Local Shareholder
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"local_shareholder_transfer"}
                  className={
                    errors.local_shareholder_transfer &&
                    touched.local_shareholder_transfer
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.local_shareholder_transfer}
                  errors={
                    errors.local_shareholder_transfer &&
                    touched.local_shareholder_transfer
                  }
                  errorsText={errors.local_shareholder_transfer}
                />
              </div>
              {/* Foreign Shareholder Transfer */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="foreign_shareholder_transfer"
              >
                Foreign Shareholder
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"foreign_shareholder_transfer"}
                  className={
                    errors.foreign_shareholder_transfer &&
                    touched.foreign_shareholder_transfer
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.foreign_shareholder_transfer}
                  errors={
                    errors.foreign_shareholder_transfer &&
                    touched.foreign_shareholder_transfer
                  }
                  errorsText={errors.foreign_shareholder_transfer}
                />
              </div>
              {/* Stamp Duty Transfer */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="stamp_duty_transfer"
              >
                Stamp Duty
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"stamp_duty_transfer"}
                  className={
                    errors.stamp_duty_transfer && touched.stamp_duty_transfer
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stamp_duty_transfer}
                  errors={
                    errors.stamp_duty_transfer && touched.stamp_duty_transfer
                  }
                  errorsText={errors.stamp_duty_transfer}
                />
              </div>
              {/* Duration Transfer */}
              <div className="col-start-2 col-span-3">
                <InputWithLabel
                  label={"Duration (No. of Days)"}
                  type={"text"}
                  idFromName={"duration_transfer"}
                  className={
                    errors.duration_transfer && touched.duration_transfer
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.duration_transfer}
                  errors={errors.duration_transfer && touched.duration_transfer}
                  errorsText={errors.duration_transfer}
                />
              </div>
            </div>
            {/* Provide Free Request (check box) */}
            <div className="flex flex-col justify-center">
              <div className="flex items-center space-x-3">
                <Toggles
                  checked={values.provide_free_request}
                  value={values.provide_free_request}
                  toggleFor={"provide_free_request"}
                  disabled={!isEdit}
                  onChange={(e) => {
                    setFieldValue("provide_free_request", e.target.checked);
                  }}
                />
                <p>Provide Free Request</p>
              </div>
              {errors.provide_free_request && (
                <InputError errorTitle={errors.provide_free_request} />
              )}
            </div>
            {values.provide_free_request && (
              <>
                {/* No. of Request Free */}
                <InputWithLabel
                  label={"No. of Request Free"}
                  type={"number"}
                  idFromName={"request_free"}
                  className={
                    errors.request_free && touched.request_free
                      ? ` input-error `
                      : ` border `
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.request_free}
                  errors={errors.request_free && touched.request_free}
                  errorsText={errors.request_free}
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                />
              </>
            )}
            {/* Remarks */}
            <TextareaWithLabel
              className={
                errors.remarks && touched.remarks ? ` input-error ` : ` border `
              }
              idFromName="remarks"
              label="Remarks"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.remarks}
              errors={errors.remarks && touched.remarks}
              errorsText={errors.remarks}
              disabled={!isEdit}
              inputClass={!isEdit ? "text-gray-400" : ""}
            />
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn uppercase"
                onClick={() => {
                  navigate("/service-settings");
                }}
              >
                Cancel
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </>
  );
};

export default AllotmentTransferShares;
